
export const initialState = {
    user: {},
    isAuthenticated: false,
    isLoading: false,
    isError: false
}

type authState = typeof initialState;

export const authReducer = (state: authState, action: any) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("user", JSON.stringify(action.payload));
            console.log(action.payload);
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };
        case "LOGIN_ERROR":
            return {
                ...state,
                isError: true,
                user: {}
            }
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        default:
            return state;
    }
}