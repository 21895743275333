import React from 'react'

interface INextIcon {
  fillColor?: string
}

export const NextIcon = ({ fillColor = '#6B7280' }: INextIcon) => {
  return (
    <div>
      <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.783 0.0880112C0.707928 0.0364703 0.620207 0.00640957 0.529301 0.00107308C0.438396 -0.0042634 0.34776 0.015327 0.267173 0.0577301C0.186587 0.100133 0.11911 0.163739 0.0720258 0.241683C0.0249413 0.319627 3.68967e-05 0.40895 0 0.500011V11.5C0.000167265 11.5911 0.02522 11.6804 0.0724523 11.7583C0.119685 11.8362 0.187301 11.8997 0.268 11.942C0.339356 11.9802 0.419063 12.0001 0.5 12C0.601059 12 0.699728 11.9693 0.783 11.912L8.783 6.41201C8.84983 6.36606 8.90447 6.30455 8.94222 6.23277C8.97998 6.16099 8.99971 6.08111 8.99971 6.00001C8.99971 5.91891 8.97998 5.83903 8.94222 5.76725C8.90447 5.69548 8.84983 5.63396 8.783 5.58801L0.783 0.0880112Z" fill={fillColor} />
      </svg>
    </div>
  )
}
