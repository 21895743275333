import React from 'react';

interface ClaimsIcon {
    fillColor: string;
}

export function ClaimsIcon({ fillColor }: ClaimsIcon): JSX.Element {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6667 0H1.33333C0.979711 0 0.640573 0.140476 0.390524 0.390524C0.140476 0.640573 0 0.979711 0 1.33333L0 14.6667C0 15.0203 0.140476 15.3594 0.390524 15.6095C0.640573 15.8595 0.979711 16 1.33333 16H14.6667C15.0203 16 15.3594 15.8595 15.6095 15.6095C15.8595 15.3594 16 15.0203 16 14.6667V1.33333C16 0.979711 15.8595 0.640573 15.6095 0.390524C15.3594 0.140476 15.0203 0 14.6667 0V0ZM13.3333 13.3333H2.66667V2.66667H13.3333V13.3333Z" fill={ fillColor } />
                <path d="M8 4H4V8H8V4Z" fill={ fillColor } />
                <path d="M12 9.33334H4V10.6667H12V9.33334Z" fill={ fillColor } />
                <path d="M12 4H9.33331V5.33333H12V4Z" fill={ fillColor } />
                <path d="M12 6.66667H9.33331V8.00001H12V6.66667Z" fill={ fillColor } />
            </svg>
        </div>
    )
}

