import ProgressBar from '@ramonak/react-progress-bar'
import { useState } from 'react';
import { useNavigate } from 'react-router';

import Button from '../../../components/Button/Button'
import ClaimQuickActionModal from '../../../components/Content/Claims/QuickActionModal';
import ContentInfo from '../../../components/ContentInfo/ContentInfo'
import { navigateToEditPage } from '../../../routes/navigators';
import { stringToFormattedDate } from '../../../utils/dateUtil';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

import './PolicyInfo.css'

interface IPolicyInfo {
  data: any;
}

const PolicyInfo = ({ data: { policy } }: IPolicyInfo) => {
  const navigate = useNavigate()
  const [openClaimQuickActions, setOpenClaimQuickActions] = useState(false);

  const getStatus = (status: string) => {
    switch (status) {
      case 'In-Force':
        return 'Active'

      // TODO: Add additional cases when design is done
      default:
        return 'In-Active'
    }
  }

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'In-Force':
        return 'sms-text';

      default:
        return 'red-text';
    }
  }

  const calculateProgressBar = (start: Date, end: Date) => {
    let totalPeriod = end.getTime() - start.getTime()
    let elapsedTime = Date.now() - start.getTime()

    return (elapsedTime / totalPeriod) * 100;
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className='policy-info'>
          <p className='policy-number'>{policy.policyNumber}</p>

          <div style={{ display: 'flex', marginBottom: '16px' }}>
            <ContentInfo
              classes='p-mr-12'
              label={<p><strong>Status</strong></p>}
              info={[
                <p><strong className={getStatusClass(policy.status)} style={{ whiteSpace: 'nowrap' }}>{getStatus(policy.status)}</strong></p>
              ]}
            />
            <ContentInfo
              classes='p-mr-12'
              label={<p><strong>Premium</strong></p>}
              info={[
                <p><strong>{`${policy.premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</strong></p>
              ]}
            />
            <ContentInfo
              classes='p-mr-12'
              label={<p><strong>Frequency</strong></p>}
              info={[
                <p><strong>{capitalizeFirstLetter(policy.frequency)}</strong></p>
              ]}
            />
          </div>

          <div>
            <ProgressBar completed={calculateProgressBar(new Date(policy.policyStartDate), new Date(policy.policyEndDate))} height='12px' isLabelVisible={false} bgColor='#E3AB1C' baseBgColor='rgba(227, 171, 28, 0.2)' />
            <div className='policy-dates'>
              <p>{stringToFormattedDate(policy.policyStartDate)}</p>
              <p>{stringToFormattedDate(policy.policyEndDate)}</p>
            </div>
          </div>
        </div>

        <div className='policy-actions'>
          <Button text='Edit' onClick={() => navigateToEditPage(navigate, policy.imei)} />
          <Button classes='p-btn sms-btn' text='Make Claim' onClick={() => setOpenClaimQuickActions(true)} />
        </div>
      </div>

      {
        openClaimQuickActions &&
        <ClaimQuickActionModal
          policyNumber={policy.policyNumber}
          deviceImei={policy.imei}
          setOpenClaimQuickActions={setOpenClaimQuickActions}
          id={policy.id}
        />

      }
    </>
  )
}

export default PolicyInfo