import React from 'react';

interface IDropdownIcon {
    strokeColor: string;
    visible?: boolean;
}

export function DropdownIcon({ strokeColor, visible=false }: IDropdownIcon): JSX.Element {
    return (
        <div>
            <svg className={visible ? 'rotate-arrow' : ''} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 1.125L5 5.625L9.5 1.125" stroke={ strokeColor } stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    )
}