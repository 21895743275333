import './switch.css'

interface ISwitch {
  isOn: boolean;
  setIsOn: VoidFunction
}

const Switch = ({ isOn, setIsOn }: ISwitch) => {
  return (
    <div className='switch-container'>
      <span className={`switch ${isOn ? 'switch-on' : ''}`} data-testid='switch' onClick={setIsOn}></span>
      <p className={`switch-text ${isOn ? 'switch-on' : ''}`}>{isOn ? 'Yes' : 'No'}</p>
    </div>
  )
}

export default Switch