import { capitalizeEachFirstLetter, capitalizeFirstLetter } from '../../../utils/stringUtils';

import './CustomerAndDeviceInfo.css'

interface ICustomerAndDeviceInfo {
  data: any;
}

const CustomerAndDeviceInfo = ({ data: { customer, policy, device, paymentHistory } }: ICustomerAndDeviceInfo) => {
  return (
    <div className='customer-and-device-container'>
      <div className='policy-customer-info'>
        <div className="customer-and-device-heading">
          <p>Customer</p>
        </div>

        <div className='policy-details'>

          <div>
            <p className='label'>Name</p>
            <p className='info'>{capitalizeEachFirstLetter(customer.fullName)}</p>
          </div>
          <div>
            <p className='label'>Email</p>
            <p className='info'>{customer.email || 'No email found'}</p>
          </div>
          <div>
            <p className='label'>Trn</p>
            <p className='info'>{customer.trn || 'No trn found'}</p>
          </div>
          <div>
            <p className='label'>Phone Number</p>
            <p className='info'>{customer.phone || 'No number found'}</p>
          </div>
        </div>
      </div>

      <div className='policy-device-info'>
        <div className="customer-and-device-heading">
          <p>Device</p>
        </div>

        <div className='policy-details'>

          {/* <div>
            <p className='label'>Type</p>
            <p className='info'>Phone</p>
          </div>
          <div>
            <p className='label'>Brand</p>
            <p className='info'>Google</p>
          </div> */}
          <div>
            <p className='label'>Model</p>
            <p className='info'>{device.phoneMake} {device.phoneModel}</p>
          </div>
          <div>
            <p className='label'>Color</p>
            <p className='info'>{device.phoneColor}</p>
          </div>
          <div>
            <p className='label'>IMEI</p>
            <p className='info'>{policy.imei}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerAndDeviceInfo