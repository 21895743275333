import axios from 'axios';

import { network } from '../../services';

const getADocument = async (id: string, index: number, bearerToken: string, opts: any) => {
    const response = await axios.get(`${network.baseApi}/claim/${id}/document-download?item=${index}`, { ...opts, headers: { Authorization: bearerToken } })
    return response;
}

const DocumentService = {
    getADocument,
}

export default DocumentService;