import { useAuthState } from '../../../contexts';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import IssueAndConditions from '../IssuesAndConditions/IssuesAndConditions';
import SupportingDocuments from '../SupportingDocuments/SupportingDocuments';
import './ClaimPhysicalContent.css'

interface IClaimPhysicalContent {
  data: any;
}

const ClaimPhysicalContent = ({ data: { claim } }: IClaimPhysicalContent) => {

  return (
    <>
      <div data-testid='deviceConditions'>
        <IssueAndConditions heading='Device Conditions' data={claim.deviceConditions} forIssue={false} />
      </div>

      <div className='customer-and-device-container'>
        <div className='liquid-claim__docs-wrapper' data-testid='deviceIssues'>
          <IssueAndConditions heading='Device Issues' data={claim.deviceIssues} forIssue={true} />
        </div>

        <div className='liquid-claim__docs-wrapper'>
          <SupportingDocuments data={claim.documents} />
        </div>
      </div>
    </>
  )
}

export default ClaimPhysicalContent