import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuthState } from '../contexts';

interface IConditionalRoute {
    requiredRoles: string[];
}

const ConditionalRoute = ({ requiredRoles }: IConditionalRoute) => {
    const location = useLocation()
    const { user } = useAuthState();

    return <>{requiredRoles.length === 0 || requiredRoles.includes(user.role) ? <Outlet /> : <Navigate to={location.pathname} />}</>
}

export default ConditionalRoute;