import { ChangeEvent, useState } from 'react';
import { InputField } from "../../../../../InputFields/InputField";
import Dropdown from '../../../../../Dropdown/Dropdown';
import DatePicker from "react-datepicker";

import './theft-detail.css';
import "react-datepicker/dist/react-datepicker.css";

interface Details {
    dateLost?: Date;
    setDateLost?: any;
    dateReported?: Date;
    setDateReported?: any;
    policeStation?: string;
    setPoliceStation?: any;
    recordingOfficer?: string;
    setRecordingOfficer?: any;
    setAdviceServiceProvider?: any;
    adviceServiceProvider?: any;
}

const TheftDetails = ({
    dateLost,
    setDateLost,
    dateReported,
    setDateReported,
    policeStation,
    setPoliceStation,
    recordingOfficer,
    setRecordingOfficer,
    adviceServiceProvider,
    setAdviceServiceProvider }: Details) => {

    const serviceOptions = () => {
        return [
            {
                id: 1,
                text: 'Yes, I have',
                selected: adviceServiceProvider,
                onClick: () => { setAdviceServiceProvider(true) }
            },
            {
                id: 2,
                text: 'No, I have not',
                selected: !adviceServiceProvider,
                onClick: () => { setAdviceServiceProvider(false) }
            },
        ]
    }


    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    return (
        <div className="theft-claim__container">
            <p className='theft-claim__title'> Please provide the the information below regarding your theft claim.</p>

            <div className="theft-claim__section">
                <div className="theft-claim__group">
                    <p className="theft-claim__label"> Date Lost </p>
                    <DatePicker
                        className='theft-datepicker'
                        selected={dateLost}
                        maxDate={new Date()}
                        onChange={(date: Date) => setDateLost(date)} />
                </div>

                <div className="theft-claim__group">
                    <p className="theft-claim__label"> Police Station Reported </p>
                    <InputField
                        value={policeStation}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setPoliceStation(e.target.value) }}
                    />
                </div>
            </div>

            <div className="theft-claim__section">
                <div className="theft-claim__group">
                    <p className="theft-claim__label">Recording Officer </p>
                    <InputField
                        value={recordingOfficer}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setRecordingOfficer(e.target.value) }}
                    />
                </div>

                <div className="theft-claim__group">
                    <p className="theft-claim__label"> Date Reported </p>
                    <DatePicker
                        className='theft-datepicker'
                        selected={dateReported}
                        onChange={(date: Date) => setDateReported(date)}
                        maxDate={new Date()}
                    />
                </div>
            </div>

            <div className="theft-claim__section-fw">
                <p className="theft-claim__label">Have you advised your service provider to shut down the number?</p>
                <Dropdown
                    className='theft-details-dropdown'
                    dropdownAreaStyles={{ minWidth: '190px' }}
                    dropdownTextStyles={{ display: 'flex', margin: 0 }}
                    options={serviceOptions()}
                    text={adviceServiceProvider ? "Yes, I have" : "No, I have not"}
                    visible={isDropdownOpen}
                    toggleVisibility={() => setIsDropdownOpen(!isDropdownOpen)} />
            </div>
        </div>
    );
}

export default TheftDetails;