import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'

import ActionFooter from '../../components/ActionFooter/ActionFooter'
import AppToaster, { ToastType } from '../../components/AppToaster/AppToaster'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Button from '../../components/Button/Button'
import { InputField } from '../../components/InputFields/InputField'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../components/PlaceholderContent/PlaceholderContent'

import { useAuthState } from '../../contexts'
import { ToasterId } from '../../enums/ToasterIds'
import PolicyService from '../../hooks/api/PolicyService'
import { addYearsToDate } from '../../utils/dateUtil'
import { capitalizeEachFirstLetter, capitalizeFirstLetter, formatApiDateString } from '../../utils/stringUtils'
import { handleRateLimit } from '../../utils/rateLimitUtil'

import './EditPolicy.css'

const EditPolicy = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [policyData, setPolicyData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)

  const [startDate, setStartDate] = useState<Date>()
  const [size, setSize] = useState<string>('')
  const [brand, setBrand] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const [imei, setImei] = useState<string>('')
  const [cost, setCost] = useState<string>('')
  const [premiumAmount, setPremiumAmount] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [trn, setTrn] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [nationalIdNumber, setNationalIdNumber] = useState('')
  const [nationalIdType, setNationalIdType] = useState('')

  // const [period, setPeriod] = useState()
  const [frequency, setFrequency] = useState()
  const { token } = useAuthState();

  useEffect(() => {
    const fetchData = async () => {
      const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

      setLoadingData(true)
      let data: any = null

      PolicyService.getAPolicy(location.state.imei, bearerToken)
        .then(response => {
          data = response.data;

          if (data.message === undefined) {

            const { customer, policy, device, paymentHistory } = data;
            console.log(data)

            setPolicyData(data)
            setFirstName(customer.firstName || '')
            setLastName(customer.lastName || '')
            setEmail(customer.email || '')
            setPhoneNumber(customer.phone || '')
            setTrn(customer.trn || '')
            setNationalIdNumber(customer.CustomerNationalId || '')
            setNationalIdType(customer.CustomerIdType || '')

            setStartDate(new Date(policy.policyStartDate))

            setBrand(device.phoneMake || '')
            setModel(device.phoneModel || '')
            setColor(device.phoneColor || '')
            setCost(device.price || 0)
            setSize(device.size || '')
            setImei(policy.imei)

            setFrequency(policy.frequency || '')
            setPremiumAmount(paymentHistory.premiumAmount)

            endLoading()
          }
        })
        .catch(error => {
          handleRateLimit(error)
          endLoading()
        })
    }

    if (!dataFetched) {
      fetchData()
    }
  }, [dataFetched, location.pathname])

  const endLoading = () => {
    setDataFetched(true)
    setLoadingData(false)
  }

  const updatePolicyDetails = async () => {
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
    setUpdating(true)
    let stringDate = formatApiDateString(startDate?.toISOString().replace('T', ' ')!)
    await PolicyService.updateAPolicy(policyData.device.id, imei, size, brand, model, color, parseFloat(cost), stringDate!, policyData.customer.id, trn, phoneNumber, email, nationalIdType, nationalIdNumber, parseFloat(premiumAmount), bearerToken)
      .then(response => {
        if (response.status === 200) {
          console.log('Updated', response)
          navigate('/policies')

          toast.custom(<AppToaster type={ToastType.SUCCESS} id={ToasterId.CreateClaimSuccess} message={`The changes were made successfully.`}></AppToaster>, { id: ToasterId.CreateClaimSuccess })
        } else {
          console.log('Update failed', response)
          setUpdating(false)
          toast.custom(<AppToaster type={ToastType.ERROR} id={ToasterId.CreateClaimError} message={`Failed to save changes. Please try again.`}></AppToaster>, { id: ToasterId.CreateClaimError })
        }
      })
      .catch(error => {
        console.log('Update failed', error)
        setUpdating(false)
        toast.custom(<AppToaster type={ToastType.ERROR} id={ToasterId.CreateClaimError} message={`Failed to save changes. An error occured.`}></AppToaster>, { id: ToasterId.CreateClaimError })
      })
  }

  const setNumberValues = (value: string, setStateMethod: any) => {
    if (!isNaN(Number(value))) {
      setStateMethod(value)
    }
  }

  return <>
    {
      (loadingData && policyData === null) &&
      <LoadingSpinner message='Just a moment. Retrieving the policy details.' />
    }

    {
      (!loadingData && policyData === null) &&
      <>
        <Breadcrumbs lastNodeText={'No Policy'} />
        <PlaceholderContent text='No data could be found for the policy to be updated.' />
      </>
    }

    {
      (policyData !== null) && <>
        <Breadcrumbs lastNodeText='Edit' />
        <div className='policy-container'>
          <div className='edit-policy_header'>
            <p className='p0 header'>Edit Policy</p>
            <p className='p0 sub-text'>Make changes to the policy by altering the details below.</p>
          </div>

          <hr className='edit-policy_hr' />

          <div className="edit-policy_edit-container">
            <div className='edit-policy_edit-details'>
              <div className='heading'>
                <p className='p0 header'>Policy Details</p>
                <p className='p0 sub-text'>Fill out the fields with the start and end date of this policy.</p>
              </div>

              <div className='content'>
                <div className="edit-policy_field-group">
                  <p className='edit-policy_label'>Policy Number</p>
                  <InputField type='input' value={policyData.policy.policyNumber} isReadOnly={true} inputStyles={{ color: '#9CA3AF', backgroundColor: '#D9D9D9' }} />
                </div>

                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>Start Date</p>
                    <DatePicker
                      className='theft-datepicker'
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      maxDate={new Date()}
                      dateFormat='MMMM d, yyyy'
                    />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>End Date</p>
                    <DatePicker
                      className='theft-datepicker disabled-edit-input'
                      selected={addYearsToDate(startDate!, 1)}
                      readOnly={true}
                      onChange={() => { }}
                      dateFormat='MMMM d, yyyy'
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className='edit-policy_edit-details'>
              <div className='heading'>
                <p className='p0 header'>Device Details</p>
                <p className='p0 sub-text'>Fill out the device details for this policy.</p>
              </div>

              <div className='content'>
                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>IMEI</p>
                    <InputField type='numeric' value={imei} onChange={(e: any) => setImei(e.target.value)} />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Brand</p>
                    <InputField type='input' value={capitalizeFirstLetter(brand)} onChange={(e: any) => setBrand(e.target.value)} />
                  </div>
                </div>

                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>Model</p>
                    <InputField type='input' value={capitalizeEachFirstLetter(model)} onChange={(e: any) => setModel(e.target.value)} />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Color</p>
                    <InputField type='input' value={capitalizeFirstLetter(color)} onChange={(e: any) => setColor(e.target.value)} />
                  </div>
                </div>

                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>Size</p>
                    <InputField type='input' value={size} onChange={(e: any) => setSize(e.target.value)} />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Cost</p>
                    <InputField type='text' value={cost} onChange={(e: any) => setNumberValues(e.target.value, setCost)} />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className='edit-policy_edit-details'>
              <div className='heading'>
                <p className='p0 header'>Customer Details</p>
                <p className='p0 sub-text'>Search and select a customer for the policy or select add new in the dropdown.</p>
              </div>

              <div className='content'>
                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>First Name</p>
                    <InputField type='input' value={capitalizeFirstLetter(firstName)} onChange={(e: any) => setFirstName(e.target.value)} />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Last Name</p>
                    <InputField type='input' value={capitalizeFirstLetter(lastName)} onChange={(e: any) => setLastName(e.target.value)} />
                  </div>
                </div>

                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>Trn</p>
                    <InputField type='input' value={trn} onChange={(e: any) => setTrn(e.target.value)} />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Phone Number</p>
                    <InputField type='tel' value={phoneNumber} onChange={(e: any) => setPhoneNumber(e.target.value)} />
                  </div>
                </div>

                <div className="claim-input-field">
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>ID Number</p>
                    <InputField type='input' value={nationalIdNumber} onChange={(e: any) => setNationalIdNumber(e.target.value)} />
                  </div>

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>ID Number Type</p>
                    <InputField type='input' value={nationalIdType} onChange={(e: any) => setNationalIdType(e.target.value)} />
                  </div>
                </div>

                <div className="claim-input-field">
                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Email</p>
                    <InputField type='input' value={email} onChange={(e: any) => setEmail(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className='edit-policy_edit-details'>
              <div className='heading'>
                <p className='p0 header'>Premium Details</p>
                <p className='p0 sub-text'>Select the period and frequency for this policy.</p>
              </div>

              <div className='content'>

                <div className="claim-input-field">
                  {/* <div className="claim-input-field"> */}
                  <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>Coverage</p>
                    <InputField type='text' value={premiumAmount} onChange={(e: any) => setNumberValues(e.target.value, setPremiumAmount)} />
                  </div>
                  {/* </div> */}
                  {/* <div className="edit-policy_field-group mr-32">
                    <p className='edit-policy_label'>Period</p>
                    <InputField type='input' value={'Placeholder'} />
                  </div> */}

                  <div className="edit-policy_field-group">
                    <p className='edit-policy_label'>Frequency</p>
                    <InputField type='input' value={frequency} onChange={(e: any) => setFrequency(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActionFooter
            leading={[
              <Button text='Cancel' onClick={() => navigate(-1)} />
            ]}
            trailing={[
              <Button
                text={
                  updating
                    ? <LoadingSpinner size={25} withContainer={false} />
                    : 'Save'
                }
                onClick={() => updatePolicyDetails()}
              />
            ]}
          />
        </div>
      </>
    }
  </>
}

export default EditPolicy