import Menu from "../Menu/Menu"
import { ReactComponent as SSLogo } from '../../assets/svg/loginPage/smart-services-logo.svg';
import "./Sidebar.css"

interface Sidebar {
}


export function Sidebar({ }: Sidebar): JSX.Element {
    return (
        <div className="container">
            <div className="logo-area">
                <div className="sidebar__icon-container">
                    <SSLogo />
                </div>
            </div>
            <Menu />
        </div>
    )
}