import { useState, useContext, useEffect } from 'react';

import ContentControls from "../../ContentControls/ContentControls";
import { IDropdown } from "../../Dropdown/Dropdown";
import { Table } from "./Table/Table";
import PolicyContext from '../../../contexts/Policy/PolicyContext';
import Footer from '../../Footer/Footer';
import ContentHeader from '../../ContentHeader/ContentHeader';
import Button from '../../Button/Button';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import PlaceholderContent from '../../PlaceholderContent/PlaceholderContent';
import SearchContext from '../../../contexts/Search/SearchContext';

export default function Policies() {
    const { data, isLoading, sortBy, setSortBy, filterBy, setFilterBy, itemsPerPage, setItemsPerPage, getAllPolicies } = useContext(PolicyContext);
    const { setSearchQuery } = useContext(SearchContext)

    const [isItemsPerPageVisible, setIsItemsPerPageVisible] = useState<boolean>(false);
    const [isSortVisible, setIsSortVisible] = useState<boolean>(false);
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

    useEffect(() => {
        getAllPolicies(1);

        return () => {
            // reset searchQuery
            setSearchQuery('any', '')
        }
    }, [itemsPerPage, sortBy, filterBy]);

    /* ---------- Policies Dropdown Props ---------- */
    const policyItemsPerPageProps: IDropdown = {
        text: `${itemsPerPage} items per page`,
        visible: isItemsPerPageVisible,
        toggleVisibility: () => setIsItemsPerPageVisible(!isItemsPerPageVisible),
        options: [
            {
                id: 5,
                text: '5 items per page',
                selected: itemsPerPage === 5,
                onClick: () => setItemsPerPage(5)
            },
            {
                id: 10,
                text: '10 items per page',
                selected: itemsPerPage === 10,
                onClick: () => setItemsPerPage(10)
            },
            {
                id: 15,
                text: '15 items per page',
                selected: itemsPerPage === 15,
                onClick: () => setItemsPerPage(15)
            },
            {
                id: 20,
                text: '20 items per page',
                selected: itemsPerPage === 20,
                onClick: () => setItemsPerPage(20)
            },
            {
                id: 25,
                text: '25 items per page',
                selected: itemsPerPage === 25,
                onClick: () => setItemsPerPage(25)
            }
        ]
    }

    const policySortProps: IDropdown = {
        text: 'Sort By Details',
        visible: isSortVisible,
        toggleVisibility: () => setIsSortVisible(!isSortVisible),
        options: [
            {
                id: 1,
                text: 'Policy Details',
                selected: sortBy === 'policyNumber',
                onClick: () => setSortBy('policyNumber'),
            },
            {
                id: 2,
                text: 'Customer',
                selected: sortBy === 'customer',
                onClick: () => setSortBy('customer'),
            },
            {
                id: 3,
                text: 'Device',
                selected: sortBy === 'device',
                onClick: () => setSortBy('device'),
            }
        ]
    }

    const policyFilterProps: IDropdown = {
        text: 'Filter',
        visible: isFilterVisible,
        toggleVisibility: () => setIsFilterVisible(!isFilterVisible),
        options: [
            {
                id: 1,
                text: 'Active',
                selected: filterBy === 'active',
                onClick: () => setFilterBy('active'),
            },
            {
                id: 2,
                text: 'In-Active',
                selected: filterBy === 'inactive',
                onClick: () => setFilterBy('inactive'),
            },
        ]
    }
    /* ---------- End of Policies Dropdown Props ---------- */

    const contentHeaderActions: JSX.Element[] = [
        // <Button classes='p-btn' text='View Archive' onClick={() => { }} />,
        // <Button classes='p-btn sms-btn' text='Create Policy' onClick={() => { }} />
    ]

    const contentInfoArray: JSX.Element[] = [
        // <p><strong></strong> Active</p>,
        // <p><strong></strong> In-Active</p>
    ]

    return <>
        {
            (data.policyData !== undefined && data.policyData.length > 0) &&
            <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
                <ContentHeader title='Policies' actions={contentHeaderActions} />
                <ContentControls
                    data-testid="custom-element"
                    contentInfoLabel={<p data-testid="total-policies-value"><strong>{data.meta.total}</strong> Total Policies</p>}
                    contentInfoArray={contentInfoArray}
                    itemsPerPageProps={policyItemsPerPageProps}
                    sortProps={policySortProps}
                    filterProps={policyFilterProps}
                />
            </div>
        }

        {
            (isLoading) &&
            <LoadingSpinner message='Just a moment. Retrieving the policies.' />
        }

        {
            (!isLoading && (data.policyData === undefined)) &&
            <PlaceholderContent text='Sorry. The policies could not be found.' />
        }

        {
            (!isLoading && (data.policyData !== undefined && data.policyData.length === 0)) &&
            <>
                <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
                    <ContentHeader title='Policies' actions={contentHeaderActions} />
                    <ContentControls
                        data-testid="custom-element"
                        contentInfoLabel={<p data-testid="total-policies-value"><strong>{data.meta.total}</strong> Total Policies</p>}
                        contentInfoArray={contentInfoArray}
                        itemsPerPageProps={policyItemsPerPageProps}
                        sortProps={policySortProps}
                        filterProps={policyFilterProps} />
                </div>
                <PlaceholderContent text='Sorry. The policies could not be found.' />
            </>
        }

        {
            (!isLoading && data.policyData !== undefined && data.policyData.length > 0) &&
            <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingBottom: '48px' }}>

                <Table headers={['Policy Details', 'Customer', 'Device', 'Status']} data={data || []} />
            </div>
        }

        {
            (data.policyData !== undefined && data.policyData.length > 0) &&
            <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingBottom: '48px' }}>
                <Footer data={data.meta || {}} itemsPerPage={itemsPerPage} onPageChange={getAllPolicies} />
            </div>
        }
    </>
}