import { createContext, useState } from 'react'

const SearchContext = createContext({
  searchQuery: '', setSearchQuery: (searchFor: string, query: string) => { },
  searchUserQuery: '', setSearchUserQuery: (search: string) => { },
  searchClaimQuery: '', setSearchClaimQuery: (search: string) => { },
  searchPolicyQuery: '', setSearchPolicyQuery: (search: string) => { }
});

export const SearchProvider = ({ children }: any) => {
  const [searchQuery, setMainSearchQuery] = useState('')
  const [searchUserQuery, setSearchUserQuery] = useState('')
  const [searchClaimQuery, setSearchClaimQuery] = useState('')
  const [searchPolicyQuery, setSearchPolicyQuery] = useState('')

  /**
   * A method that sets the search `query` based on the `searchFor`
    value that represents the page path to be searched.
   `
   * @param searchFor The path for the page to search from.
   * @param query String to search for.
   */
  const setSearchQuery = (searchFor: string, query: string) => {
    setMainSearchQuery(query)

    switch (searchFor) {
      case 'policies':
        setSearchPolicyQuery(query)
        break;
      case 'users':
        setSearchUserQuery(query)
        break;
      case 'claims':
        setSearchClaimQuery(query)
        break;

      default:
        setMainSearchQuery('')
        setSearchPolicyQuery('')
        setSearchUserQuery('')
        setSearchClaimQuery('')
        break;
    }
  }

  return (
    <SearchContext.Provider value={{
      searchQuery, setSearchQuery, searchUserQuery, setSearchUserQuery,
      searchPolicyQuery, setSearchPolicyQuery, searchClaimQuery, setSearchClaimQuery
    }}>
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContext