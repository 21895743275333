interface ICloseIcon {
  strokeColor?: string
  className?: string
}

const CloseIcon = ({ strokeColor = '#6B7280', className }: ICloseIcon) => {
  return (
    <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L13 13" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13 1L1 13" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default CloseIcon