import DragDropFile from '../../../../../DragAndDrop/DragDropFile'

import './supporting-documents.css'

export interface ICondition {
  id: number;
  name: string;
}

interface ISupportingDocuments {
  claimType: string;
  supportingDocFiles: File[];
  setSupportingDocFiles: any;
}

const Bold = ({ children }: any) => {
  return <strong className="supporting-doc-title-bold">{children}</strong>
}

const SupportingDocuments = ({ claimType, supportingDocFiles, setSupportingDocFiles }: ISupportingDocuments) => {
  const getSupportingDocText = (claimType: string) => {
    switch (claimType.toLowerCase()) {
      case 'liquid damage':
      case 'physical damage':
        return <>Please upload the <Bold>Assessment Result</Bold> (from Technician), <Bold>Quotation</Bold>, <Bold>Signed copy of the claim form</Bold> and <Bold>Loaner Device form</Bold> (if customer has received a loaner device ). </>
      case 'theft':
        return <>Please upload the <Bold>Police report</Bold>, <Bold>Quotation</Bold>, <Bold>Signed copy of the claim form</Bold>, <Bold>Loaner Device form</Bold> (if customer has received a loaner device ) and <Bold>Agreement/Declaration</Bold> to state that recovered phones will belong to the Insurer. </>
    }
  }

  return (
    <div className='physical-damage-claim__container'>
      <p className="supporting-doc-claim__title">{getSupportingDocText(claimType)}
        Filetype should be either <Bold>PDF, JPG</Bold> or <Bold>Doc.</Bold></p>

      <div className='physical-damage-claim__issues-list-wrapper'>
        <div className='physical-damage-claim__issues-list'>

          <DragDropFile uploadedFiles={supportingDocFiles} setUploadedFiles={setSupportingDocFiles} />

        </div>
        <div className="list-shadow-bottom" style={{ bottom: -1 }} />
      </div>
    </div>
  )
}

export default SupportingDocuments