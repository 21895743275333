import { useLocation, useNavigate } from 'react-router'

import Button from '../Button/Button'
import LeftArrow from '../../assets/svg/LeftArrow'
import { DropdownIcon } from '../../assets/svg/pageHeader/DropdownIcon'
import { capitalizeFirstLetter } from '../../utils/stringUtils'

import './Breadcrumbs.css'

interface IBreadcrumbs {
  lastNodeText: string
}

const Breadcrumbs = ({ lastNodeText }: IBreadcrumbs) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = location.pathname.split('/').filter(p => p !== '');


  return (
    <div className='breadcrumbs-container'>
      <span className='breadcrumb-back' onClick={() => navigate(-1)}><LeftArrow /> Back</span>
      <span className="breadcrumb-pipe"></span>
      <div className="breadcrumbs">
        {
          pathArray.map(item => {
            if (item !== pathArray[pathArray.length - 1]) {
              return <>
                <Button text={capitalizeFirstLetter(item, false)} styles={{ ...styles, background: '#fff' }} onClick={() => { }} />
                <span className='crumb-separator'>
                  <DropdownIcon strokeColor='#6B7280' />
                </span>
              </>
            }
          })
        }

        <Button text={lastNodeText} styles={{ ...styles, background: 'rgba(75, 85, 99, 0.1)' }} onClick={() => { }} />
      </div>
    </div>
  )
}

const styles = {
  fontFamily: 'Roboto',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '14px',

  height: '30px',
  // minWidth: 0,
  width: 'auto',
  padding: '0 16px',
  border: '1px solid #4B5563',
  borderRadius: '20px'
}

export default Breadcrumbs