import Button from '../../../components/Button/Button';
import { capitalizeFirstLetter, convertToFile } from '../../../utils/stringUtils';
import DocumentService from '../../../hooks/api/DocumentService';
import { useAuthState } from '../../../contexts';

import './SupportingDocuments.css'
import { useState } from 'react';
import { AxiosResponse } from 'axios';

interface IDeviceDetails {
    data?: any[];
    onClick?: string;
}

const SupportingDocuments = ({ data }: IDeviceDetails) => {
    const { token } = useAuthState();

    const donwloadData = (datum: any, index: number) => {
        const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;
        DocumentService.getADocument(datum.claim_id, index, bearerToken, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                var file = new Blob([response.data], { type: response.headers['content-type'] });
                console.log(response);
                let url = window.URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = url;
                a.download = datum.name;
                a.click();
            })
            .catch(err => {
                console.log(err);
            });
    }


    return (
        <div style={{ width: '100%' }}>
            <div className="device-condtitions-heading">
                <p>Supporting Documents</p>
            </div>
            <div className='device-condtitions-details'>
                <table className="device-conditions-table">
                    <tbody>
                        {data?.map((datum: any, index: number) => {
                            return (
                                <tr className='device-conditions-table-row' key={index}>
                                    <td className="device-condtitions-table-data" key={datum.name}>
                                        <p className="supporting-doc__title">{datum.name}</p>
                                        <Button onClick={() => donwloadData(datum, index)} classes={'supporting-doc-button'} text={'Download'}></Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default SupportingDocuments