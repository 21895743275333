import { useEffect, useRef, useState } from "react"

import Button from "../../../Button/Button"
import Dropdown from "../../../Dropdown/Dropdown"
import { InputField } from "../../../InputFields/InputField"

import "./create-user-modal.css"

interface ICreateUserModal {
  setShowCreateUserModal: any;
  createUser: (user: any) => any
}

const CreateUserModal = ({ setShowCreateUserModal, createUser }: ICreateUserModal) => {
  const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [position, setPosition] = useState('')

  const [isFieldsValid, setIsFieldValid] = useState({
    firstNameValid: false,
    lastNameValid: false,
    emailValid: false,
    roleValid: false,
    positionValid: false
  })

  const firstSubmitAttempt = useRef(true)

  const roles = [
    'Administrator',
    'Customer Service Representative',
    'Insurer'
  ]

  useEffect(() => {
    const { firstNameValid, lastNameValid, emailValid, roleValid, positionValid } = isFieldsValid
    if (firstNameValid && lastNameValid && emailValid && roleValid && positionValid) {
      createUser({
        firstName,
        lastName,
        email,
        role,
        position
      })
      setShowCreateUserModal(false)
    };
  }, [isFieldsValid])

  const roleOptions = () => {
    return [
      {
        id: 1,
        text: 'Administrator',
        selected: role === 'Administrator',
        onClick: () => setRole('Administrator')
      },
      {
        id: 2,
        text: 'Customer Service Representative',
        selected: role === 'Customer Service Representative',
        onClick: () => setRole('Customer Service Representative')
      },
      {
        id: 3,
        text: 'Insurer',
        selected: role === 'Insurer',
        onClick: () => setRole('Insurer')
      }
    ]
  }

  const validateInputs = () => {
    if (firstSubmitAttempt.current) {
      firstSubmitAttempt.current = false
    }

    setIsFieldValid({
      firstNameValid: firstName.length > 0,
      lastNameValid: lastName.length > 0,
      emailValid: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email),
      roleValid: roles.includes(role),
      positionValid: position.length > 0
    })
  }

  const setInvalidStyle = (condition: boolean) => {
    return (!firstSubmitAttempt.current && condition) ? invalidFieldStyles : undefined;
  }

  const shouldShowErrorText = (condition: boolean) => {
    return (!firstSubmitAttempt.current && condition);
  }

  return (
    <div className='default-modal-background'>
      <div className="create-user-modal__container" data-testid='create-user-modal'>
        <div className="create-user-modal__form">
          <p className="create-user-modal__heading">New User</p>
          <p className="create-user-modal__subheading">Fill out the user details in the form below.</p>

          <div className="create-user-modal__group">
            <div className="create-user-modal__field-group">
              <p className="create-user-modal__label">First Name</p>
              <InputField
                placeholder="John"
                value={firstName}
                inputStyles={{ ...inputStyles, ...setInvalidStyle(!isFieldsValid.firstNameValid) }}
                onChange={(e: any) => setFirstName(e.target.value)}
              />
              {shouldShowErrorText(!isFieldsValid.firstNameValid) &&
                <p className="create-user-modal__error-text">Please enter the user's first name.</p>
              }
            </div>

            <div className="create-user-modal__field-group">
              <p className="create-user-modal__label">Last Name</p>
              <InputField
                placeholder="Doe"
                value={lastName}
                inputStyles={{ ...inputStyles, ...setInvalidStyle(!isFieldsValid.lastNameValid) }}
                onChange={(e: any) => setLastName(e.target.value)}
              />
              {shouldShowErrorText(!isFieldsValid.lastNameValid) &&
                <p className="create-user-modal__error-text">Please enter the user's last name.</p>
              }
            </div>
          </div>

          <div className="create-user-modal__group">
            <div className="create-user-modal__field-group">
              <p className="create-user-modal__label">Email Address</p>
              <InputField
                placeholder="j.doe@mail.com"
                value={email}
                inputStyles={{ ...inputStyles, ...setInvalidStyle(!isFieldsValid.emailValid) }}
                onChange={(e: any) => setEmail(e.target.value)}
              />
              {shouldShowErrorText(!isFieldsValid.emailValid) &&
                <p className="create-user-modal__error-text">Please enter a valid email address.</p>
              }
            </div>

            <div className="create-user-modal__field-group">
              <p className="create-user-modal__label">Role</p>
              <Dropdown
                className='create-user-modal__dropdown'
                styles={setInvalidStyle(!isFieldsValid.roleValid)}
                dropdownAreaStyles={{ minWidth: '190px' }}
                dropdownTextStyles={{ display: 'flex', margin: 0, ...(role !== '' && inputStyles) }}
                options={roleOptions()}
                text={role || "Select Role"}
                visible={isRoleDropdownOpen}
                toggleVisibility={() => setIsRoleDropdownOpen(!isRoleDropdownOpen)}
              />
              {shouldShowErrorText(!isFieldsValid.roleValid) &&
                <p className="create-user-modal__error-text">Please select the user's role.</p>
              }
            </div>
          </div>

          <div className="create-user-modal__field-group">
            <p className="create-user-modal__label">Position</p>
            <InputField
              placeholder="Manager"
              value={position}
              inputStyles={{ ...inputStyles, ...setInvalidStyle(!isFieldsValid.positionValid) }}
              onChange={(e: any) => setPosition(e.target.value)}
            />
          </div>
          {shouldShowErrorText(!isFieldsValid.positionValid) &&
            <p className="create-user-modal__error-text">Please enter the user's position.</p>
          }
        </div>

        <div className="create-user-modal__actions">
          <Button text="Cancel" onClick={() => setShowCreateUserModal(false)} />
          <Button classes="sms-btn" text="Save" onClick={() => validateInputs()} />
        </div>
      </div>
    </div>
  )
}

const invalidFieldStyles = {
  borderColor: '#EF4444'
}

const inputStyles = {
  fontFamily: 'Roboto',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '14px',
  color: '#374151',
}

export default CreateUserModal