import React from 'react'

import { CheckedIcon } from '../../assets/svg/table/CheckedIcon'

import { stopClickPropagation } from '../../utils/propagationUtil';
import './Checkbox.css'

interface ICheckbox {
  checked: boolean;
  onClick: any;
}

const Checkbox = ({ checked = false, onClick }: ICheckbox) => {
  return (
    <div className={`checkbox-container ${checked ? 'checked' : ''}`} data-testid='checkbox' onClick={(e) => { stopClickPropagation(e, onClick) }}>
      {checked && <CheckedIcon />}
    </div>
  )
}

export default Checkbox