import "./HeaderProfile.css";
import { useAuthDispatch, useAuthState } from "../../../contexts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DropdownIcon } from "../../../assets/svg/pageHeader/DropdownIcon";
import Dropdown from "../../Dropdown/Dropdown";
import { logout } from "../../../contexts/Auth/actions";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";

export default function HeaderProfile() {
    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { user, token } = useAuthState();
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();

    

    const handleLogout = async () => {
        const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

        try {
            let response = await logout(bearerToken, dispatch);

            console.log("LOGOUT_RESPONSE", response);
            navigate('/');

        } catch (error) {
            console.log("LOGOUT_ERROR", error);
        }
    };

    const renderDropdownOptions = (logout: string,) => {
        return [
            {
                id: 1,
                text: logout,
                selected: false,
                onClick: handleLogout
            },
        ];
    }

    return (
        <div className="header-profile-container">
            <div className="header-profile-icon">
                {user?.firstName.charAt(0)}
                {user?.lastName.charAt(0)}
            </div>
            <div className="header-profile-info">
                <p className="header-profile-name">{`${user?.firstName} ${user?.lastName}`}</p>
                <p className="header-profile-role"> {user?.position}</p>
            </div>
            <div className="header-profile-dropdown">

                <Dropdown
                    text={<DropdownIcon strokeColor="black" />}
                    testId="profile-dropdown"
                    visible={isDropdownOpen}
                    toggleVisibility={() => setIsDropdownOpen(!isDropdownOpen)}
                    hideArrowIcon={true}
                    styles={{ border: 'none', marginLeft: '0px' }}
                    dropdownAreaStyles={{ minWidth: '190px' }}
                    dropdownTextStyles={{ display: 'flex', margin: 0 }}
                    options={renderDropdownOptions("Logout")} />
            </div>
        </div>
    )
}