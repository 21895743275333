import './claim-type.css';
import { InputField } from "../../../../../InputFields/InputField";

interface ClaimInfo {
    setStepOneComplete?: any;
    claimType?: string;
    policyNum?: string;
}

const TheftClaimStepOne = ({policyNum, claimType}: ClaimInfo) => {
    return (
        <div className="theft-claim__container">
            <p className='theft-claim__title'> Please provide the policy number for which you are making a claim on.</p>
            <div className='theft-claim__field-group'>
                <p className="theft-claim__label"> Claim Type </p>
                <InputField
                    type='input'
                    value={claimType}
                />
            </div>

            <div>
                <p className="theft-claim__label"> Policy Number </p>
                <InputField
                    type='input'
                    value={policyNum}
                />
            </div>
        </div>
    );
}

export default TheftClaimStepOne;