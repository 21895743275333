import React, { ReactNode } from 'react'

import './ContentInfo.css';

interface IContentInfo {
  label: ReactNode;
  info: ReactNode[];
  classes?: string;
  mode?: 'top-down' | 'left-right';
  type?: 'default' | 'status';
}

const ContentInfo = ({ label, info, classes = '', mode = 'left-right', type = 'default'}: IContentInfo) => {
  return (
    <div className={`content-info ${classes} ${mode == 'top-down' ? 'top-down' : null}`}>
      <div data-testid="total-policies" className='content-count' style={mode == 'top-down' ? { justifyContent: 'center' } : {}}>
        {label}
      </div>

      <div data-testid="policy-status" className={`content-status ${type === 'default' ? 'content-status-default-bg' : 'content-status-claims-status-bg'}`}>
        {info.map(node => (
          <>
            {info.indexOf(node) !== 0 && <div className="info-pipe"></div>}
            {node}
          </>
        ))}
      </div>
    </div>
  )
}

export default ContentInfo