import { ReactNode } from 'react';

import './ActionFooter.css'

interface IActionFooter {
  classes?: string;
  /**
   * The leading list of buttons.
   */
  leading: ReactNode[];
  /**
   * The trailing list of buttons.
   */
  trailing: ReactNode[];
}

const ActionFooter = ({ classes = '', leading, trailing }: IActionFooter) => {
  return (
    <div className={`action-footer-container ${classes}`}>
      <div className='leading-actions'>
        {leading}
      </div>

      <div className='trailing-actions'>
        {trailing}
      </div>
    </div>
  )
}

export default ActionFooter