import { ChangeEvent, useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import './login.css';
import Button from "../../components/Button/Button";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { InputField } from "../../components/InputFields/InputField";
import { ReactComponent as SSLogo } from '../../assets/svg/loginPage/smart-services-logo.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/loginPage/close-icon.svg';
import { ViewPasswordIcon } from '../../assets/svg/loginPage/ViewPasswordIcon';
import { HidePasswordIcon } from '../../assets/svg/loginPage/HidePasswordIcon';
import { useAuthDispatch, useAuthState } from '../../contexts';
import { loginUserAxios } from '../../contexts/Auth/actions';


const Login = () => {
    const inputFieldStyles = {
        marginBottom: 20,
        paddingLeft: 24,
        height: 62,
        width: '477px'
    };


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    // const [invalidLogin, setInvalidLogin] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAuthDispatch();
    const { loading, errorMessage } = useAuthState();

    // useEffect(() => {
    //     if (errorMessage !== null) {
    //         setInvalidLogin(true);
    //     } else {
    //         setInvalidLogin(false);
    //     }
    // }, []);

    const handleLogin = async (event: any) => {
        event.preventDefault();

        let payload = { email, password };
        try {
            let response = await loginUserAxios(dispatch, payload);
            console.log("LOGIN_RESPONSE", response);
            if (!response.authorization) return
            navigate('/policies');
        } catch (error) {
            console.log("LOGIN_ERROR", error);
        }
    };

    const handleKeyPress = async (event: KeyboardEvent) => {
        if (event.key == 'Enter') {
            if (email !== '' && password !== '') {
                let payload = { email, password };
                try {
                    let response = await loginUserAxios(dispatch, payload);
                    console.log("LOGIN_RESPONSE", response);
                    if (!response.authorization) return
                    navigate('/policies');
                } catch (error) {
                    console.log("LOGIN_ERROR", error);
                }
            }
        }
    };

    return (
        <div className="login__wrapper">
            <div className='login__background'> </div>
            <div className="login__container">
                <div className="login__header">
                    <div className="login__icon-container">
                        <SSLogo />
                    </div>
                    <p className='login__header-text'> Sign into your account.</p>
                </div>

                {
                    errorMessage &&
                    <div className='login__error'>
                        <p className='login__error-text'>Incorrect email or password.</p>
                        <CloseIcon className='login__close-icon' />
                    </div>
                }

                <div className="login__form-container">
                    <InputField
                        value={email}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        inputStyles={inputFieldStyles}
                        placeholder='Enter your email'
                        onKeyDown={handleKeyPress}
                    />

                    <InputField
                        value={password}
                        type={showPassword ? 'text' : "password"}
                        icon={showPassword && password.length > 0 ? <HidePasswordIcon /> : <ViewPasswordIcon />}
                        inputContainerStyles='login__icon-input'
                        adjustIconPadding='icon__padding'
                        iconStyles='icon__style'
                        iconOnClick={() => { setShowPassword(!showPassword) }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        inputStyles={inputFieldStyles}
                        placeholder='Enter your password'
                        onKeyDown={handleKeyPress}
                    />

                    <Button
                        classes='login__btn'
                        text={loading ? <LoadingSpinner fillColor='white' withContainer={false} size={35} /> : 'Login'}
                        onClick={handleLogin}
                    />

                    <a className='login__forgot-pw' href='#'> Forgot password?</a>
                </div>

            </div>
            <p className='login__copyright-text'>© Smart Mobile Solutions 2023. All Rights Reserved.</p>
        </div>
    );
}

export default Login;