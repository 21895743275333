import toast from 'react-hot-toast';

import CloseIcon from '../../assets/svg/CloseIcon';
import { ReactComponent as SuccessToast } from '../../assets/svg/toast/success-toast-icon.svg'
import { ReactComponent as ErrorToast } from '../../assets/svg/toast/error-toast-icon.svg'

import './app-toaster.css'

// The enums are used as class names for styling.
export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error'
}

enum CloseIconColor {
  'success' = '#65A30D',
  'error' = '#FF4949'
}

interface IAppToaster {
  id: string;
  type?: ToastType;
  message?: string;
}

const AppToaster = ({ id, type, message }: IAppToaster) => {
  const clsIconStrokeColor = type ? CloseIconColor[type] : undefined

  return (
    <div className={`toaster-container ${type}`}>
      <div className='toaster-content'>
        {type === ToastType.SUCCESS && <SuccessToast />}
        {type === ToastType.ERROR && <ErrorToast />}
        <p>{message}</p>
      </div>

      <div className='cls-icon' onClick={() => toast.remove(id)}>
        <CloseIcon strokeColor={clsIconStrokeColor} />
      </div>
    </div>
  )
}

export default AppToaster