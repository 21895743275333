import toast from "react-hot-toast"

import AppToaster, { ToastType } from "../components/AppToaster/AppToaster"
import { ToasterId } from "../enums/ToasterIds"

export const handleRateLimit = (error: any, callback?: VoidFunction) => {
  const { data, status, statusText } = error.response
  const timeoutKey = 'rateLimitTimeoutDate'

  if (status === 429 && statusText.toLowerCase() === 'too many requests' && data) {
    removeOldTimeoutDateIfExists(timeoutKey)

    if (localStorage.getItem(timeoutKey) == null) {
      const now = new Date()
      now.setSeconds(now.getSeconds() + data.waitTime)

      const waitTimeDateUtc = Date.UTC(
        now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()
      );
      const dateTimeString = new Date(waitTimeDateUtc).toISOString();

      localStorage.setItem(timeoutKey, dateTimeString)
      setTimeout(() => {
        localStorage.removeItem(timeoutKey)
      }, data.waitTime * 1000)
    }

    let timeoutDate = new Date(localStorage.getItem(timeoutKey)!)

    let now = new Date();
    let now_utc = Date.UTC(
      now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()
    );
    let currentDate = new Date(now_utc);

    const waitSeconds = Math.floor((timeoutDate.getTime() - currentDate.getTime()) / 1000)

    toast.custom(<AppToaster type={ToastType.ERROR} id={ToasterId.CreateClaimSuccess} message={`Request limit exceeded. Please wait for ${waitSeconds} seconds to try again.`}></AppToaster>, { id: ToasterId.RateLimit })
  }

  if (callback) {
    callback()
  }
}

/* Method to clean up the ratelimit timeout local storage variable if it
   failed to be removed automatically. The prevents the wait time from displaying
   incorrectly if that should happen. */
const removeOldTimeoutDateIfExists = (timeoutKey: string) => {
  if (localStorage.getItem(timeoutKey) !== null) {
    let timeoutDate = new Date(localStorage.getItem(timeoutKey)!)

    let now = new Date();
    let now_utc = Date.UTC(
      now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()
    );
    let currentDate = new Date(now_utc);

    if (timeoutDate < currentDate) {
      localStorage.removeItem(timeoutKey)
    }
  }
}