import { createContext, useContext, useEffect, useReducer, useState } from "react"
import { toast } from "react-hot-toast";

import AppToaster, { ToastType } from "../../components/AppToaster/AppToaster";
import UserService from "../../hooks/api/UserService";
import { ToasterId } from "../../enums/ToasterIds";
import { createAction } from "../../utils/reducerUtils";
import { IUserData, USER_ACTION_TYPES } from "./userTypes";
import { useAuthState } from "../Auth/context";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { wasRequestUnauthorized } from "../../utils/toasterErrorUtils";
import { handleRateLimit } from "../../utils/rateLimitUtil";
import SearchContext from "../Search/SearchContext";

const USER_INITIAL_STATE = {
  data: {
    meta: {},
    userData: []
  },
  isLoading: false,
  sortBy: 'name',
  setSortBy: () => { },
  filterBy: 1,
  setFilterBy: () => { },
  itemsPerPage: 1,
  setItemsPerPage: () => { },
  createUser: (user: any) => { },
  getAllUsers: (page: number) => { }
}

const userLocalStorageNames = {
  'userSort': 'userSort',
  'userFilter': 'userFilter',
  'userItemsPerPage': 'userItemsPerPage'
}

const UserContext = createContext<IUserData>(USER_INITIAL_STATE);

const userReducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SET_USER_DATA:
      return {
        ...state,
        data: payload
      }
    case USER_ACTION_TYPES.SET_USER_SORT_BY:
      return {
        ...state,
        sortBy: payload
      }
    case USER_ACTION_TYPES.SET_USER_FILTER_BY:
      return {
        ...state,
        filterBy: payload
      }
    case USER_ACTION_TYPES.SET_USER_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: payload
      }

    default:
      throw new Error(`Unhandled type ${type} in userReducer.`);
  }
}

export const UserProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(userReducer, USER_INITIAL_STATE);
  const { searchUserQuery } = useContext(SearchContext)
  const { token } = useAuthState();
  const [isLoading, setIsLoading] = useState(false)

  const { data, sortBy, filterBy, itemsPerPage } = state;

  useEffect(() => {
    // fetchAllUsers(1)
  }, [])

  useEffect(() => {
    const sort = localStorage.getItem(userLocalStorageNames.userSort || '');
    const filter = Number.parseInt(localStorage.getItem(userLocalStorageNames.userFilter) || '1');
    const ipp = Number.parseInt(localStorage.getItem(userLocalStorageNames.userItemsPerPage) || '1');

    dispatch(createAction(USER_ACTION_TYPES.SET_USER_SORT_BY, sort));
    dispatch(createAction(USER_ACTION_TYPES.SET_USER_FILTER_BY, filter));
    dispatch(createAction(USER_ACTION_TYPES.SET_USER_ITEMS_PER_PAGE, ipp));
  }, []);

  const createUser = (user: any) => {
    setIsLoading(true)
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

    UserService.createUser(user, bearerToken).then(response => {
      setIsLoading(false)
      toast.custom(<AppToaster type={ToastType.SUCCESS} id={ToasterId.CreateUserSuccess} message="New user created successfully."></AppToaster>, { id: ToasterId.CreateUserSuccess })
    }).catch(error => {
      setIsLoading(false)
      if (!wasRequestUnauthorized(error)) {
        toast.custom(<AppToaster type={ToastType.ERROR} id={ToasterId.CreateUserError} message="Failed to create the user."></AppToaster>, { id: ToasterId.CreateUserError })
      }
    })
  }

  function getAllUsers(page: number) {

    setIsLoading(true)
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

    UserService.getAllUsers(page, itemsPerPage, searchUserQuery, sortBy, bearerToken).then(response => {
      const policies = response.data;

      setUserData(policies);
      setSortBy(sortBy)
      setFilterBy(filterBy)
      setIsLoading(false)
    }).catch(err => {
      handleRateLimit(err)
      setIsLoading(false)
    })
  }

  const setUserData = (data: any) => {
    dispatch(createAction(USER_ACTION_TYPES.SET_USER_DATA, data))
  }

  const setSortBy = (sortBy: string) => {
    localStorage.setItem(userLocalStorageNames.userSort, sortBy)
    dispatch(createAction(USER_ACTION_TYPES.SET_USER_SORT_BY, sortBy))
  }

  const setFilterBy = (id: number) => {
    localStorage.setItem(userLocalStorageNames.userFilter, id.toString())
    dispatch(createAction(USER_ACTION_TYPES.SET_USER_FILTER_BY, id))
  }

  const setItemsPerPage = (id: number) => {
    localStorage.setItem(userLocalStorageNames.userItemsPerPage, id.toString())
    dispatch(createAction(USER_ACTION_TYPES.SET_USER_ITEMS_PER_PAGE, id))
  }

  return (
    <UserContext.Provider value={{ data, isLoading, sortBy, setSortBy, filterBy, setFilterBy, itemsPerPage, setItemsPerPage, createUser, getAllUsers }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext