import { ReactNode } from 'react';

import './content-header.css';

interface IContentHeader {
  title: string;
  actions?: ReactNode[];
}

const ContentHeader = ({ title, actions = [] }: IContentHeader) => {

  return (
    <div className='content-header-container'>
      <p>{title}</p>
      <div>
        {
          actions.map(action => action)
        }
      </div>
    </div>
  )
}

export default ContentHeader