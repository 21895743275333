import { CSSProperties } from "react";

import "./InputField.css"

interface InputFieldType {
    icon?: any;
    placeholder?: string;
    type?: string;
    onChange?: any;
    value?: string;
    isReadOnly?: boolean,
    inputStyles?: CSSProperties;
    iconStyles?: string;
    adjustIconPadding?: string;
    iconOnClick?: VoidFunction;
    inputContainerStyles?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    onKeyDown?: any;
}

export function InputField({ icon, placeholder, type, onChange, value, isReadOnly = false, inputStyles = {}, onBlur, onFocus, inputContainerStyles, adjustIconPadding, iconStyles, iconOnClick, onKeyDown }: InputFieldType): JSX.Element {
    return (
        <div className={`inputfield-container ${inputContainerStyles}`}>
            {
                icon &&
                <div className={`icon ${iconStyles}`} onClick={iconOnClick}>{icon}</div>
            }

            <input
                className={`inputfield ${icon ? `has-icon ${adjustIconPadding}` : ''}`}
                style={inputStyles}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                readOnly={isReadOnly}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
            />
        </div>
    )
}