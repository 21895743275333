export function ViewPasswordIcon(): JSX.Element {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_918_159)">
                    <path d="M8 10.7576C8.86061 10.7576 9.59091 10.4576 10.1909 9.85757C10.7909 9.25757 11.0909 8.52726 11.0909 7.66666C11.0909 6.80605 10.7909 6.07575 10.1909 5.47575C9.59091 4.87575 8.86061 4.57575 8 4.57575C7.13939 4.57575 6.40909 4.87575 5.80909 5.47575C5.20909 6.07575 4.90909 6.80605 4.90909 7.66666C4.90909 8.52726 5.20909 9.25757 5.80909 9.85757C6.40909 10.4576 7.13939 10.7576 8 10.7576ZM8 9.70302C7.4303 9.70302 6.94848 9.50605 6.55455 9.11211C6.16061 8.71817 5.96364 8.23635 5.96364 7.66666C5.96364 7.09696 6.16061 6.61514 6.55455 6.2212C6.94848 5.82726 7.4303 5.63029 8 5.63029C8.5697 5.63029 9.05152 5.82726 9.44545 6.2212C9.83939 6.61514 10.0364 7.09696 10.0364 7.66666C10.0364 8.23635 9.83939 8.71817 9.44545 9.11211C9.05152 9.50605 8.5697 9.70302 8 9.70302ZM8 13.1212C6.2303 13.1212 4.6303 12.6182 3.2 11.6121C1.7697 10.6061 0.70303 9.2909 0 7.66666C0.70303 6.04242 1.7697 4.72726 3.2 3.7212C4.6303 2.71514 6.2303 2.21211 8 2.21211C9.7697 2.21211 11.3697 2.71514 12.8 3.7212C14.2303 4.72726 15.297 6.04242 16 7.66666C15.297 9.2909 14.2303 10.6061 12.8 11.6121C11.3697 12.6182 9.7697 13.1212 8 13.1212ZM8 12.0303C9.46667 12.0303 10.8152 11.6333 12.0455 10.8394C13.2758 10.0454 14.2121 8.98787 14.8545 7.66666C14.2121 6.34545 13.2758 5.28787 12.0455 4.49393C10.8152 3.69999 9.46667 3.30302 8 3.30302C6.53333 3.30302 5.18485 3.69999 3.95455 4.49393C2.72424 5.28787 1.78182 6.34545 1.12727 7.66666C1.78182 8.98787 2.72424 10.0454 3.95455 10.8394C5.18485 11.6333 6.53333 12.0303 8 12.0303Z" fill="#9CA3AF" />
                </g>
                <defs>
                    <clipPath id="clip0_918_159">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}