import { useReducer } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { routes } from "../src/routes/routes";
import Home from './pages/DefaultLayout/DefaultLayout';
import Login from './pages/Login/Login';
import { authReducer, initialState } from './reducers/authReducer';
import PrivateRoutes from './routes/privateRoutes';
import ConditionalRoute from './routes/conditionalRoute';
import { AuthProvider } from './contexts';
import { UserProvider } from './contexts/User/UserContext';
import { ClaimProvider } from './contexts/Claim/ClaimContext';
import { PolicyProvider } from './contexts/Policy/PolicyContext';
import { SearchProvider } from './contexts/Search/SearchContext';

function App() {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthProvider value={{ state, dispatch }}>
      <SearchProvider>
        <UserProvider>
          <PolicyProvider>
            <ClaimProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<Login />} path="/login" />
                  <Route element={<PrivateRoutes />}>
                    <Route element={<Home />} path="/" >
                      {routes.map((route) => (
                        <Route element={<ConditionalRoute requiredRoles={route.authorizedRoles} />}>
                          <Route key={route.index} path={route.path} element={route.component} />
                        </Route>
                      ))}
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </ClaimProvider>
          </PolicyProvider>
        </UserProvider>
      </SearchProvider>
    </AuthProvider>
  );
}

export default App;
