import Button from '../Button/Button';
import { ReactComponent as DangerIcon } from '../../assets/svg/claims/danger-icon.svg'

import './upload-file-modal.css'

interface IUploadFileModal {
  setOpenModal: any;
  rejectedFiles: any;
  onButtonClick?: any;
  // handleChange?: any;
}

const UploadFileModal = ({ setOpenModal, rejectedFiles, onButtonClick }: IUploadFileModal) => {

  const checkCount = (count: number) => {
    if (count > 0) {
      return "and " + count + " other(s)"
    } else {
      return;
    }
  }

  const clickUpload = () => {
    onButtonClick()
    setOpenModal(false)
  }

  const truncate = (filename: string) => {
    let splitName = filename.split(".")
    return filename.substring(0, 18) + "..." + splitName[splitName.length - 1]
  }

  return (
    <div className='default-modal-background'>
      <div className='inactive-policy-modal__container'>
        <div className="inactive-policy-modal__content">
          <div>
            <DangerIcon />
          </div>

          <span>File size Exceeded</span>
          <p>Your file “{truncate(rejectedFiles.name)}” {checkCount(rejectedFiles.count)} cant be uploaded because of its size.
            File sizes should not be larger the 20mb.</p>
        </div>
        <div className='upload-button-group'>
          {/* <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".pdf,.doc,.jpeg,.png" /> */}
          <Button classes="" styles={{ width: '45%' }} text='Upload Another' onClick={clickUpload}></Button>
          {/* <Button   onClick={() => setOpenModal(false)} /> */}
          <Button classes='sms-btn' text='Done' styles={{ width: '45%' }} onClick={() => setOpenModal(false)} />
        </div>
      </div>
    </div>
  )
}

export default UploadFileModal