export interface IUserData {
  data: {
    meta: any,
    userData: any[]
  },
  isLoading: boolean,
  sortBy: string;
  setSortBy: (sortBy: string) => void,
  filterBy: number,
  setFilterBy: (id: number) => void,
  itemsPerPage: number,
  setItemsPerPage: (id: number) => void,
  createUser: (user: any) => void,
  /**
   * Fetches all users from the database for the given `page`.
   */
  getAllUsers: (page: number) => any,
}

export const USER_ACTION_TYPES = {
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_SORT_BY: 'SET_USER_SORT_BY',
  SET_USER_FILTER_BY: 'SSET_USER_FILTER_BY',
  SET_USER_ITEMS_PER_PAGE: 'SET_USER_ITEMS_PER_PAGE'
}