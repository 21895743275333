import toast from "react-hot-toast"
import AppToaster, { ToastType } from "../components/AppToaster/AppToaster"
import { ToasterId } from "../enums/ToasterIds"

export const wasRequestUnauthorized = (error: any) => {
  if (error && error.response.statusText === 'Unauthorized' && error.response.status === 401) {
    toast.custom(
      <AppToaster type={ToastType.ERROR} id={ToasterId.CreateUserError} message="This user is not authorized to complete this action." />,
      { id: ToasterId.CreateUserError }
    )
    return true
  }
  return false
}