import { Link, useLocation } from 'react-router-dom';

import { DashboardIcon } from '../../assets/svg/navigation/DashboardIcon';
import { PolicyIcon } from '../../assets/svg/navigation/PolicyIcon';
import { ClaimsIcon } from '../../assets/svg/navigation/ClaimsIcon';
import { CustomersIcon } from '../../assets/svg/navigation/CustomersIcon';
import { useAuthState } from '../../contexts';
import { routes } from '../../routes/routes'

import './menu.css';

interface IMenu { }

const Menu = ({ }: IMenu) => {
  const location = useLocation();
  const { user } = useAuthState();

  const activeColor = 'white';
  const inactiveColor = '#8D9AA5';

  const shouldAllowRouteAccess = (routeIndex: number) => {
    const route = routes.find(route => route.index === routeIndex)

    return route?.authorizedRoles.length === 0
      || route?.authorizedRoles.includes(user.role)
  }

  return (
    <div className='menu-container' role='menu'>
      {
        shouldAllowRouteAccess(1) && false &&
        <Link to='/' className={location.pathname === '/' ? 'nav-active' : ''}>
          <DashboardIcon fillColor={location.pathname === '/' ? activeColor : inactiveColor} />
          <p className='menu-title'>Dashboard</p>
        </Link>
      }

      {
        shouldAllowRouteAccess(2) &&
        <Link to='/policies' className={location.pathname.includes('/policies') ? 'nav-active' : ''}>
          <PolicyIcon fillColor={location.pathname.includes('/policies') ? activeColor : inactiveColor} />
          <p className='menu-title'>Policy</p>
        </Link>
      }

      {
        shouldAllowRouteAccess(3) && false &&
        <Link to='claims' className={location.pathname.includes('/claims') ? 'nav-active' : ''}>
          <ClaimsIcon fillColor={location.pathname.includes('/claims') ? activeColor : inactiveColor} />
          <p className='menu-title'>Claims</p>
        </Link>
      }

      {
        shouldAllowRouteAccess(4) && false &&
        <Link to='customers' className={location.pathname.includes('/customers') ? 'nav-active' : ''}>
          <CustomersIcon fillColor={location.pathname.includes('/customers') ? activeColor : inactiveColor} />
          <p className='menu-title'>Customers</p>
        </Link>
      }

      {
        shouldAllowRouteAccess(5) &&
        <Link to='users' className={location.pathname.includes('/users') ? 'nav-active' : ''}>
          <CustomersIcon fillColor={location.pathname.includes('/users') ? activeColor : inactiveColor} />
          <p className='menu-title'>Users</p>
        </Link>
      }
      {
        shouldAllowRouteAccess(6) &&
        <Link to='claims' className={location.pathname.includes('/claims') ? 'nav-active' : ''}>
          <ClaimsIcon fillColor={location.pathname.includes('/claims') ? activeColor : inactiveColor} />
          <p className='menu-title'>Claims</p>
        </Link>
      }
    </div>
  )
}

export default Menu