import { CSSProperties } from 'react';

import './placeholder-content.css'

interface IPlaceholderContent {
  text: string;
  style?: CSSProperties;
}

const PlaceholderContent = ({ text, style = {} }: IPlaceholderContent) => {
  return (
    <div className='placeholder-content__container' style={style}>
      <p>{text}</p>
    </div>
  )
}

export default PlaceholderContent