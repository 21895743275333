import React from 'react';

interface PolicyIcon {
    fillColor: string;
}

export function PolicyIcon({ fillColor }: PolicyIcon): JSX.Element {
    return (
        <div>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.412 0H0V12C0 12.7072 0.280952 13.3855 0.781049 13.8856C1.28115 14.3857 1.95942 14.6667 2.66667 14.6667H13.3333C14.0406 14.6667 14.7189 14.3857 15.219 13.8856C15.719 13.3855 16 12.7072 16 12V2.66667H7.74533L6.412 0Z" fill={ fillColor } />
            </svg>

        </div>
    )
}
