import React from 'react';
import './sidebar.css';
import { ReactComponent as ActiveIcon } from '../../../../../assets/svg/claims/active-icon.svg';
import { ReactComponent as CompleteIcon } from '../../../../../assets/svg/claims/completed-icon.svg';

interface SidebarItems {
    onStepOne?: boolean;
    onStepTwo?: boolean;
    onStepThree?: boolean,
    onStepFour?: boolean,
    theft?: boolean;
    physicalDamage?: boolean;
    liquidDamage?: boolean;
    stepOneComplete?: boolean;
    stepTwoComplete?: boolean;
    stepThreeComplete?: boolean;
    stepFourComplete?: boolean;
}
const QuickActionSidebar = ({
    onStepOne,
    onStepTwo,
    onStepThree,
    onStepFour,
    physicalDamage,
    liquidDamage,
    theft,
    stepOneComplete,
    stepTwoComplete,
    stepThreeComplete,
    stepFourComplete
}: SidebarItems) => {
    return (
        <div className='sidebar__menu-container'>
            <div className='sidebar__header'>
                <p className='sidebar__title'>New Claim</p>
                <p className='sidebar__intructions'> Follow the steps below to successfully file a claim.</p>
            </div>

            <div className='sidebar__items'>
                <div className='sidebar__item-container'>
                    <div className='sidebar__step-status'>
                        {
                            onStepOne ? <ActiveIcon className='sidebar__active-icon' /> : stepOneComplete ? <CompleteIcon /> : null
                        }
                    </div>
                    <div className='sidebar__step-details'>
                        <p className='sidebar-step'> STEP 1 </p>
                        <p className='sidebar__step-name'> Claim Type</p>
                    </div>
                </div>

                <div className='sidebar__stepper'></div>

                <div className='sidebar__item-container'>
                    <div className='sidebar__step-status'>
                        {
                            onStepTwo ? <ActiveIcon className='sidebar__active-icon' /> : stepTwoComplete ? <CompleteIcon /> : null
                        }
                    </div>
                    <div className='sidebar__step-details'>
                        <p className='sidebar-step'> STEP 2 </p>
                        <p className='sidebar__step-name'>
                            {
                                theft && 'Theft Details'
                            }
                            {
                                physicalDamage && 'Device Issues'
                            }
                            {
                                liquidDamage && 'Statement'
                            }
                        </p>
                    </div>
                </div>

                <div className='sidebar__stepper'></div>

                <div className='sidebar__item-container'>
                    <div className='sidebar__step-status'>
                        {
                            onStepThree ? <ActiveIcon className='sidebar__active-icon' /> : stepThreeComplete ? <CompleteIcon /> : null
                        }
                    </div>
                    <div className='sidebar__step-details'>
                        <p className='sidebar-step'> STEP 3 </p>
                        <p className='sidebar__step-name'>
                            {
                                theft && 'Statement'
                            }
                            {
                                physicalDamage && 'Device Condition'
                            }
                            {
                                liquidDamage && 'Supporting Documents'
                            }
                        </p>
                    </div>
                </div>

                {
                    (theft || physicalDamage) &&
                    <>
                        <div className='sidebar__stepper'></div>

                        <div className='sidebar__item-container'>
                            <div className='sidebar__step-status'>
                                {
                                    onStepFour ? <ActiveIcon className='sidebar__active-icon' /> : stepFourComplete ? <CompleteIcon /> : null
                                }
                            </div>
                            <div className='sidebar__step-details'>
                                <p className='sidebar-step'> STEP 4 </p>
                                <p className='sidebar__step-name'>
                                    Supporting Documents
                                </p>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default QuickActionSidebar;