import { useContext } from "react";
import { useLocation } from "react-router";

import { InputField } from "../../InputFields/InputField";
import { SearchIcon } from "../../../assets/svg/pageHeader/SearchIcon";
import PolicyContext from "../../../contexts/Policy/PolicyContext";
import UserContext from "../../../contexts/User/UserContext";
import SearchContext from "../../../contexts/Search/SearchContext";
import ClaimContext from "../../../contexts/Claim/ClaimContext";

import "./Searchbar.css"

interface Searchbar {
  searchQuery: string,
  onChange?: any
}

export default function Searchbar(): JSX.Element {
  const { searchQuery, setSearchQuery } = useContext(SearchContext)
  const { getAllPolicies } = useContext(PolicyContext);
  const { getAllUsers } = useContext(UserContext)
  const { getAllClaims } = useContext(ClaimContext)
  const location = useLocation()


  const handleSearch = async () => {
    const pathArray = location.pathname.split('/')

    switch (pathArray[1]) {
      case 'policies':
        getAllPolicies(1);
        break;

      case 'users':
        getAllUsers(1);
        break;

      case 'claims':
        getAllClaims(1);
        break;

      default:
        break;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <div className="header-search-container">
      <InputField
        type='text'
        placeholder="Search by name, policy number, or IMEI"
        icon={<SearchIcon strokeColor="#9C93AF" />}
        onChange={(e: any) => setSearchQuery(location.pathname.split('/')[1], e.target.value)}
        value={searchQuery}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}