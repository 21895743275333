import React from 'react';

interface SearchIcon {
    strokeColor: string;
}

export function SearchIcon({ strokeColor }: SearchIcon): JSX.Element {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_202_4484)">
                    <path d="M15.5 15.5L12.5 12.5" stroke={ strokeColor } stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.5 12.5C9.81371 12.5 12.5 9.81371 12.5 6.5C12.5 3.18629 9.81371 0.5 6.5 0.5C3.18629 0.5 0.5 3.18629 0.5 6.5C0.5 9.81371 3.18629 12.5 6.5 12.5Z" stroke={ strokeColor } stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_202_4484">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}