import { useContext, useRef, useState } from 'react';
import { useNavigate } from "react-router";

import { ReactComponent as ColumnIcon } from "../../../../assets/svg/table/column-sort.svg";
import { Status } from "../../../Status/Status";
import Dropdown from "../../../Dropdown/Dropdown";
import { EllipsesIcon } from "../../../../assets/svg/pagination/EllipsesIcon";
import Checkbox from "../../../Checkbox/Checkbox";
import { stringToFormattedDate } from '../../../../utils/dateUtil';
import ClaimQuickActionModal from "../../Claims/QuickActionModal";
import InactivePolicyModal from '../../Claims/InactivePolicyModal/InactivePolicyModal';
import { navigateToEditPage } from '../../../../routes/navigators';

import "./Table.css"
import PolicyContext from '../../../../contexts/Policy/PolicyContext';

interface InputField {
  headers?: any;
  data?: any;
}

export function Table({ headers, data }: InputField): JSX.Element {

  const navigate = useNavigate();
  
  const [modalText, setModalText] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isEllipsisDropdownOpen, setIsEllipsisDropdownOpen] = useState(false);
  const [openClaimQuickActions, setOpenClaimQuickActions] = useState(false);
  const [openInactivePolicyModal, setOpenInactivePolicyModal] = useState(false);
  const policyNumber = useRef('');
  const imei = useRef('');
  const policyId = useRef('');
  const id = useRef('')

  const addOrToggleCheckedItems = (dataId: string) => {
    let newArr: string[] = [];
    if (checkedItems.includes(dataId)) {
      newArr = checkedItems.filter(item => item !== dataId);
    } else {
      newArr = [...checkedItems, dataId];
    }
    setCheckedItems(newArr);
  }

  const checkAllItemsToggle = () => {
    if (checkedItems.length === data.policyData.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(data.policyData.map((dat: any) => dat.imei));
    }
  }

  function areAllKeysFilled(obj: any) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
                return false; 
            }
        }
    }
    return true; 
}

  const openClaimCreationModal = (data: any, id: string, policyNum: string, deviceImei: string, policyStatus: string, index: number) => {
    if (areAllKeysFilled(data.policyData[index]) == false) {
      setModalText("A claim cannot be created for a policy with missing data. Please update policy details.")
      setOpenInactivePolicyModal(true)
      setOpenClaimQuickActions(false)
    } else {
      if (policyStatus !== 'In-Force') {
        setModalText("A claim cannot be created for an in-active policy.")
        setOpenInactivePolicyModal(true)
        return;
      }
      policyId.current = id;
      policyNumber.current = policyNum;
      imei.current = deviceImei;
      setOpenClaimQuickActions(true)
    }
  }

  const renderEllipsisDropdownOptions = (id: string, policyId: string, deviceimei: string, policyStatus: string, index: number) => {
    return [
      {
        id: 1,
        text: 'Create Claim',
        classes: policyStatus !== 'In-Force'
          ? 'disabled-ellipsis-btn'
          : '',
        selected: false,
        onClick: () => openClaimCreationModal(data, id, policyId, deviceimei, policyStatus, index)
      },
      {
        id: 2,
        text: 'Edit',
        selected: false,
        onClick: () => navigateToEditPage(navigate, deviceimei)
      },
      // {
      //   id: 3,
      //   text: 'Delete',
      //   selected: false,
      //   onClick: () => { }
      // },
      // {
      //   id: 4,
      //   text: 'Archive',
      //   selected: false,
      //   onClick: () => { }
      // },
    ];
  }

  return (
    <>
      <table className="table">
        <thead className="table-head">
          <tr>
            <th className="table-header" scope="col" key={'checkbox'}>
              <Checkbox checked={checkedItems.length === data.policyData.length} onClick={() => checkAllItemsToggle()} />
            </th>
            {headers?.map((header: any) => {
              return (
                <th className="table-header" scope="col" key={header}>
                  <p className="table-head-title">
                    {header} 
                    {/* {(typeof header === 'string') && <ColumnIcon />} */}
                  </p>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.policyData.map((datum: any, index: number) => {
            return (
              <tr className={`table-row ${(checkedItems.includes(datum.imei)) ? 'checked' : ''}`} key={index} onClick={() => navigate(`/policies/${datum.imei}`, { state: { imei: datum.imei } })}>
                <td>
                  <Checkbox checked={checkedItems.includes(datum.imei)} onClick={() => addOrToggleCheckedItems(datum.imei)} />
                </td>
                <td className="table-data">
                  <p className="table-data-top">{datum.policyNumber}</p>
                  <p className="table-data-bottom">{stringToFormattedDate(datum.dateCreated)}</p>
                </td>
                <td>
                  <p className="table-data-top">{datum.fullName}</p>
                  <p className="table-data-bottom">{datum.email || 'No email available'}</p>
                </td>
                <td>
                  <p className="table-data-top"></p>
                  <p className="table-data-bottom truncate">IMEI - {datum.imei}</p>
                </td>
                <td>
                  <div className="status">
                    <Status title={datum.status == "In-Force" ? "Active" : "Inactive"}
                      classes={datum.status === "In-Force" ? 'active' : 'inactive'} />

                    <div className="ellipsis-btn">
                      <Dropdown
                        text={<EllipsesIcon />}
                        visible={isEllipsisDropdownOpen}
                        toggleVisibility={() => setIsEllipsisDropdownOpen(!isEllipsisDropdownOpen)}
                        hideArrowIcon={true}
                        styles={policyRecordEllipsisDropdownStyle}
                        dropdownAreaStyles={{ minWidth: '190px' }}
                        dropdownTextStyles={{ display: 'flex', margin: 0 }}
                        options={renderEllipsisDropdownOptions(datum.id, datum.policyNumber, datum.imei, datum.status, index)} />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
        {
          openClaimQuickActions &&
          <ClaimQuickActionModal
            policyNumber={policyNumber.current}
            deviceImei={imei.current}
            setOpenClaimQuickActions={setOpenClaimQuickActions}
            id={policyId.current}
          />

        }

        {
          openInactivePolicyModal &&
          <InactivePolicyModal setOpenModal={setOpenInactivePolicyModal} text={modalText} />
        }
      </table>
    </>
  )
}

const policyRecordEllipsisDropdownStyle = {
  height: '26px',
  padding: '2px 5px',
  backgroundColor: '#E5E7EB',
  border: 'none',
  borderRadius: '8px',
}