import { Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast'

import "./DefaultLayout.css"
import { Sidebar } from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";

export default function Home() {
    return (
        <div className="default-layout-container" >
            <Toaster position='top-center' />
            <Sidebar />
            <div className="default-layout-content">
                <Header />
                <Outlet />
            </div>
        </div>
    )
}