import React from 'react'

interface IPreviousIcon {
  fillColor?: string
}

export const PreviousIcon = ({ fillColor = '#6B7280' }: IPreviousIcon) => {
  return (
    <div>
      <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.217 0.0880112C8.29207 0.0364703 8.37979 0.00640957 8.4707 0.00107308C8.5616 -0.0042634 8.65224 0.015327 8.73283 0.0577301C8.81341 0.100133 8.88089 0.163739 8.92797 0.241683C8.97506 0.319627 8.99996 0.40895 9 0.500011V11.5C8.99983 11.5911 8.97478 11.6804 8.92755 11.7583C8.88032 11.8362 8.8127 11.8997 8.732 11.942C8.66064 11.9802 8.58094 12.0001 8.5 12C8.39894 12 8.30027 11.9693 8.217 11.912L0.217 6.41201C0.150174 6.36606 0.0955286 6.30455 0.0577755 6.23277C0.0200224 6.16099 0.000294685 6.08111 0.000294685 6.00001C0.000294685 5.91891 0.0200224 5.83903 0.0577755 5.76725C0.0955286 5.69548 0.150174 5.63396 0.217 5.58801L8.217 0.0880112Z" fill={fillColor} />
      </svg>
    </div>
  )
}
