import axios from 'axios';

import { dateToFormattedString } from '../../utils/dateUtil';

const CLAIMS_URL = process.env.REACT_APP_API

export type DataDeviceIssues = {
    deviceNotCharging: boolean;
    deviceRebootingIssue: boolean;
    screenBlank: boolean;
    deviceNotComingOn: boolean;
    screenWhite: boolean;
    screenBroken: boolean;
    notHearingPerson: boolean;
    peopleCantHearWhenISpeak: boolean;
    notAbleToHearPhoneRing: boolean;
    notAbleToPlayMultimedia: boolean;
    unableToHearFromHeadphoneJack: boolean;
    unableToPairBluetooth: boolean;
    deviceFreezes: boolean;
    unableToAccessCamera: boolean;
    cameraNotTakingPictures: boolean;
    cameraRebootsWhenTakingPicture: boolean;
    cameraGivingTrouble: boolean;
    infiniteBoot: boolean;
    digitizerBroken: boolean;
    devicePerformanceSlow: boolean;
    deviceShutsDown: boolean;
    notReadingSimCard: boolean;
    warrantAssessment: boolean;
    touchIsntWorking: boolean;
    screenFlickering: boolean;
    deviceShutsDownIntermittently: boolean;
    notReadingMemoryCard: boolean;
}

export type DataDeviceConditions = {
    deviceHasPower: boolean;
    deviceCharging: boolean;
    deviceRebootingCondition: boolean;
    volumeButtonsWork: boolean;
    cameraWorking: boolean;
    enterButtonWorking: boolean;
    optionButtonWorking: boolean;
    speakersWorking: boolean;
    touchScreenWorking: boolean;
    lensScratched: boolean;
    topOfDeviceScratched: boolean;
    deviceBootsUp: boolean;
    scrollButtonWorking: boolean;
    returnButtonWorking: boolean;
    keyboardButtonWorking: boolean;
    ableToPlayMedia: boolean;
    lcdOperational: boolean;
    sideRailsScratched: boolean;
    backOfDeviceScratched: boolean;
    lowerEndOfDeviceScratched: boolean;
}

export type DocumentData = {
    name: string;
    file: File;
}

const createTheftClaim = async (id: string, bearerToken: string, imei: string, documents: DocumentData[], dateLost: Date, policeStation: string, recordingOfficer: string, dateReported: Date, numberShutDown: any, statement: string) => {
    let response = await axios.post(CLAIMS_URL + `/policy/${id}/claim`,
        {
            type: 'theft',
            deviceImei: imei,
            theftDetails: {
                dateLost: dateToFormattedString(dateLost),
                policeStation,
                recordingOfficer,
                dateReported: dateToFormattedString(dateReported),
                numberShutDown
            },
            statement,
            documents
        },
        { headers: { Authorization: bearerToken, "Content-type": "application/json", "Accept": "application/json" } });
    return response.data;
}

const createLiquidClaim = async (id: string, bearerToken: string, imei: string, documents: DocumentData[], devicePassword: string, statement: string) => {

    let response = await axios.post(CLAIMS_URL + `/policy/${id}/claim`,
        {
            type: 'liquid damage',
            deviceImei: imei,
            devicePassword,
            statement,
            documents
        },
        { headers: { Authorization: bearerToken, "Content-type": "application/json", "Accept": "application/json" } }
    );

    return response.data;
}

const createPhysicalClaim = async (
    id: string,
    bearerToken: string,
    imei: string,
    document: DocumentData[],
    devicePassword: string,
    deviceCondition: DataDeviceConditions,
    deviceIssue: DataDeviceIssues
) => {
    let response = await axios.post(CLAIMS_URL + `/policy/${id}/claim`,
        {
            type: 'physical damage',
            devicePassword: devicePassword,
            deviceImei: imei,
            documents: document,
            deviceIssues: deviceIssue,
            deviceConditions: deviceCondition,
        },
        { headers: { Authorization: bearerToken, "Content-type": "application/json", "Accept": "application/json" } });
        return response.data;
}

const getClaimDetails = async (imei: string, claimId: number, bearerToken: string) => {
    let response = await axios.get(
        CLAIMS_URL + `/claims/${imei}`,
        {
            params: { claimId },
            headers: { Authorization: bearerToken, "Content-type": "application/json", "Accept": "application/json" }
        }
    )
    return response;
}

const getComments = async (claimId: number, bearerToken: string) => {
    let response = await axios.get(
        CLAIMS_URL + `/claims/${claimId}/comments`,
        {
            headers: { Authorization: bearerToken, "Content-type": "application/json", "Accept": "application/json" }
        }
    )
    return response;
}

const createComment = async (claimId: number, status: string, user: string, content: string, bearerToken: string) => {
    const response = await axios.post(
        CLAIMS_URL + `/claims/comment`,
        {
            claimId: claimId,
            user: user,
            content: content,
            status: status,
        },
        {
            headers: { Authorization: bearerToken, "Content-type": "application/json", "Accept": "application/json" }
        }

    )
    return response.data
}

const ClaimService = {
    createTheftClaim,
    createLiquidClaim,
    createPhysicalClaim,
    getClaimDetails,
    getComments,
    createComment
}

export default ClaimService;