import React, { CSSProperties, ReactNode } from 'react'

import { DropdownIcon } from '../../assets/svg/pageHeader/DropdownIcon';
import { stopClickPropagation } from '../../utils/propagationUtil';
import Button from '../Button/Button';

import './Dropdown.css';

export interface Option {
  id: number;
  text: string;
  classes?: string,
  selected: boolean;
  onClick: VoidFunction;
}

/**
 * The type for the dropdown component. Only one of either
 * the `options` or `children` should be passed to a Dropdown
 * instance.
 */
export interface IDropdown {
  text: ReactNode;
  visible: boolean;
  toggleVisibility: VoidFunction;
  className?: string;
  hideArrowIcon?: boolean;
  options?: Option[];
  testId?: string;
  styles?: CSSProperties;
  optionStyles?: CSSProperties;
  dropdownAreaStyles?: CSSProperties;
  dropdownTextStyles?: CSSProperties;
  children?: ReactNode;
}

const Dropdown = ({ text, visible, className, hideArrowIcon = false, options, styles = {}, dropdownAreaStyles = {}, dropdownTextStyles = {}, optionStyles = {}, testId = 'dropdown', children, toggleVisibility }: IDropdown) => {
  return (
    <div className={`dropdown ${className}`} data-testid={testId} onClick={(e) => stopClickPropagation(e, toggleVisibility)} style={{ ...styles }}>
      <p className='dropdown-text' style={{ ...dropdownTextStyles }}>{text}</p>
      {!hideArrowIcon && <DropdownIcon strokeColor='#222426' visible={visible} />}

      {/* ---------- Dropdown area ---------- */}
      {visible &&
        <div className="dropdown-area" style={dropdownAreaStyles}>
          {/* ---------- Option buttons ---------- */}
          {options
            ? options.map(option => (
              <Button
                classes={`${option.classes || ''} ${option.selected ? 'selected' : ''}`}
                text={option.text}
                onClick={option.onClick}
                styles={optionStyles}
              />
            ))

            : children
          }
        </div>
      }
    </div>
  )
}

export default Dropdown