import React, { useState } from 'react';
import './index.css';
import Button from '../../../Button/Button';
import ClaimQuickActions from './SelectClaimType/SelectClaimType';
import ClaimTypeDetails from './ClaimTypeDetails/ClaimTypeDetails';

interface QuickActions {
    id: string,
    policyNumber: string;
    deviceImei: string;
    setOpenClaimQuickActions?: any;
};

const ClaimQuickActionModal = ({ id, policyNumber, deviceImei, setOpenClaimQuickActions }: QuickActions) => {
    const [showClaimTypeMenu, setShowClaimTypeMenu] = useState(true);
    const [physicalDamage, setPhysicalDamage] = useState(false);
    const [liquidDamage, setLiquidDamage] = useState(false);
    const [theft, setTheft] = useState(false);

    const [onStepOne, setOnStepOne] = useState(true);
    const [onStepTwo, setOnStepTwo] = useState(false);
    const [onStepThree, setOnStepThree] = useState(false);
    const [onStepFour, setOnStepFour] = useState(false);

    const [stepOneComplete, setStepOneComplete] = useState(false);
    const [stepTwoComplete, setStepTwoComplete] = useState(false);
    const [stepThreeComplete, setStepThreeComplete] = useState(false);
    const [stepFourComplete, setStepFourComplete] = useState(false);


    const handleNext = () => {
        if (!theft && !physicalDamage) {
            setShowClaimTypeMenu(true);
        }
        else {
            setShowClaimTypeMenu(false);
        }
    }
    return (
        <div className='default-modal-background'>

            <div className='quick-actions__container'>
                <div className='modal-content__container'>
                    {
                        showClaimTypeMenu === true ?
                            <div className='quick-actions__select-type'>
                                <ClaimQuickActions
                                    theft={theft}
                                    setTheft={setTheft}
                                    physicalDamage={physicalDamage}
                                    setPhysicalDamage={setPhysicalDamage}
                                    liquidDamage={liquidDamage}
                                    setLiquidDamage={setLiquidDamage}
                                />
                                <div className='quick-action__footer'>
                                    <Button text='Cancel' onClick={() => { setOpenClaimQuickActions(false) }} />
                                    <Button classes='claim-details__next-btn' text='Next' onClick={() => setShowClaimTypeMenu(false)} />
                                </div>
                            </div>
                            :
                            <ClaimTypeDetails
                                id = {id}
                                policyNumber={policyNumber}
                                deviceImei={deviceImei}
                                onStepOne={onStepOne}
                                setOnStepOne={setOnStepOne}
                                onStepTwo={onStepTwo}
                                setOnStepTwo={setOnStepTwo}
                                onStepThree={onStepThree}
                                setOnStepThree={setOnStepThree}
                                onStepFour={onStepFour}
                                setOnStepFour={setOnStepFour}
                                physicalDamage={physicalDamage}
                                setPhysicalDamage={setPhysicalDamage}
                                liquidDamage={liquidDamage}
                                setLiquidDamage={setLiquidDamage}
                                theft={theft}
                                setTheft={setTheft}
                                setShowClaimTypeMenu={setShowClaimTypeMenu}
                                stepOneComplete={stepOneComplete}
                                setStepOneComplete={setStepOneComplete}
                                stepTwoComplete={stepTwoComplete}
                                setStepTwoComplete={setStepTwoComplete}
                                stepThreeComplete={stepThreeComplete}
                                setStepThreeComplete={setStepThreeComplete}
                                stepFourComplete={stepFourComplete}
                                setStepFourComplete={setStepFourComplete}
                                closeModal={() => setOpenClaimQuickActions(false)}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default ClaimQuickActionModal;