import React, { useState } from 'react';

import { PhysicalDamageIcon } from '../../../../../assets/svg/claims/PhysicalDamageIcon';
import { LiquidDamageIcon } from '../../../../../assets/svg/claims/LiquidDamageIcon';
import { TheftIcon } from '../../../../../assets/svg/claims/TheftIcon';

import './SelectClaimType.css';

interface ClaimTypes {
    physicalDamage?: any;
    setPhysicalDamage?: any;
    liquidDamage?: any;
    setLiquidDamage?: any;
    theft?: any;
    setTheft?: any;
};

const ClaimQuickActions = ({ physicalDamage, setPhysicalDamage, liquidDamage, setLiquidDamage, theft, setTheft }: ClaimTypes) => {
    const selectPhysicalDamage = () => {
        setPhysicalDamage(true);
        setLiquidDamage(false);
        setTheft(false);
    };

    const selectLiquidDamage = () => {
        setPhysicalDamage(false);
        setLiquidDamage(true);
        setTheft(false);
    }

    const selectTheft = () => {
        setPhysicalDamage(false);
        setLiquidDamage(false);
        setTheft(true);
    };
    return (
        <div className='claim-type__wrapper'>
            <p className='claim-type__header'> Select the claim type </p>
            <div className='claim-type__container'>

                <div className={physicalDamage ? 'claim-type__btn selected-btn' : 'claim-type__btn'} onClick={selectPhysicalDamage} data-testid='claim-type__btn-physical'>
                    <div className={physicalDamage ? 'claim-type__icon-container selected-btn-icon' : 'claim-type__icon-container'}>
                        <PhysicalDamageIcon />
                    </div>
                    <p className='claim-type__text'> Physical Damage</p>
                </div>

                <div className={liquidDamage ? 'claim-type__btn selected-btn' : 'claim-type__btn'} onClick={selectLiquidDamage} data-testid='claim-type__btn-liquid'>
                    <div className={liquidDamage ? 'claim-type__icon-container selected-btn-icon' : 'claim-type__icon-container'}>
                        <LiquidDamageIcon />
                    </div>
                    <p className='claim-type__text'> Liquid Damage</p>
                </div>

                <div className={theft ? 'claim-type__btn selected-btn' : 'claim-type__btn'} onClick={selectTheft} data-testid='claim-type__btn-theft'>
                    <div className={theft ? 'claim-type__icon-container selected-btn-icon' : 'claim-type__icon-container'}>
                        <TheftIcon />
                    </div>
                    <p className='claim-type__text'> Theft</p>
                </div>
            </div>
        </div>
    );
};

export default ClaimQuickActions;