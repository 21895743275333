import { InputField } from '../../../../../InputFields/InputField'

import './physical-damage-claim-type.css'

interface IPhysicalDamageClaimType {
  policyNumber: string;
  claimType: string;
  devicePassword: string;
  setDevicePassword: any;
}

const PhysicalDamageClaimType = ({ policyNumber, claimType, devicePassword, setDevicePassword }: IPhysicalDamageClaimType) => {
  return (
    <div className="physical-damage-claim__container">
      <p className='physical-damage-claim__title'>Please provide the policy number and device password for which you are making a claim on.</p>

      <div className="physical-damage-claim__field-group">
        <p className='physical-damage-claim__label'>Claim Type</p>
        <InputField type='input' value={claimType} isReadOnly={true} />
      </div>

      <div className="claim-input-field">
        <div className="physical-damage-claim__field-group mr-32">
          <p className='physical-damage-claim__label'>Policy Number</p>
          <InputField type='input' value={policyNumber} isReadOnly={true} />
        </div>

        <div className="physical-damage-claim__field-group">
          <p className='physical-damage-claim__label'>Device Password</p>
          <InputField type='password' placeholder='- - - - -' value={devicePassword} onChange={setDevicePassword} />
        </div>
      </div>
    </div>
  )
}

export default PhysicalDamageClaimType