import "./Status.css"

interface Status {
    title: string;
    titleStyle?: any;
    style?: any;
    classes?: string;
}

export function Status({ title, style, titleStyle, classes = '' }: Status): JSX.Element {
    return (
        <div className={`status-container ${classes}`} style={style}>
            <p className="status-title" style={titleStyle}>{title}</p>
        </div>
    )
}