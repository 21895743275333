//note that the use of the non-null assertion operator '!' is used and may cause the application to fail if currentUser is ever null`
let user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!).user : "";
let token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!).authorization : "";

export interface ActionObj {
    type: string;
    payload: any;
    error: string;
}

export const initialState = {
    user: "" || user,
    token: "" || token,
    loading: false,
    errorMessage: ''
};

export const AuthReducer = (initialState: any, action: ActionObj) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            localStorage.setItem('currentUser', JSON.stringify(action.payload));
            return {
                ...initialState,
                user: action.payload.user,
                token: action.payload.authorization,
                loading: false
            };
        case "REQUEST_LOGOUT":
            return {
                ...initialState,
                token: action.payload,
                loading: true
            };
        case "LOGOUT_SUCCESS":
            localStorage.removeItem('currentUser');
            localStorage.removeItem('token');
            return {
                user: "",
                token: "",
                loading: false,
                errorMessage: ''
            };
        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };
        case "LOGOUT_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}