import Button from '../../../Button/Button'
import { ReactComponent as DangerIcon } from '../../../../assets/svg/claims/danger-icon.svg'

import './inactive-policy-modal.css'

interface IInactivePolicyModal {
  setOpenModal: any;
  text: string;
}

const InactivePolicyModal = ({ setOpenModal, text }: IInactivePolicyModal) => {
  return (
    <div className='default-modal-background'>
      <div className='inactive-policy-modal__container'>
        <div className="inactive-policy-modal__content">
          <div>
            <DangerIcon />
          </div>

          <span>Cannot create claim</span>
          {/* <p>A claim cannot be created for an in-active policy.</p> */}
          <p>{text}</p>
        </div>
        <Button classes='sms-btn' text='OK' styles={{ width: '100%' }} onClick={() => setOpenModal(false)} />
      </div>
    </div>
  )
}

export default InactivePolicyModal