import axios from 'axios';
import { network } from '../../services';

interface LoginPayload {
    email: string;
    password: string;
};

export async function loginUserAxios(dispatch: any, loginpayload: LoginPayload) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await axios.post(
            `${network.baseApi}/auth/login`,
            loginpayload,
            { headers: { "Content-type": "application/json", "Accept": "application/json" } }
        );

        if (response.data.authorization) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
            return response.data;
        }
        else {

            dispatch({ type: 'LOGIN_ERROR', error: response.data.errors[0] });
        }
    }
    catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function logout(bearerToken: string, dispatch: any) {
    try {
        dispatch({ type: 'REQUEST_LOGOUT' });
        const response = await axios.post(
            `${network.baseApi}/auth/logout`,
            {},
            { headers: { Authorization: bearerToken } })

        if (response.status == 200) {
            dispatch({ type: 'LOGOUT_SUCCESS', payload: response.data.message });
            return response.data;
        }
        else {

            dispatch({ type: 'LOGOUT_ERROR', error: response.data.message });
        }
    }
    catch (error) {
        dispatch({ type: 'LOGOUT_ERROR', error: error });
        console.log(error)
    }
}