import { useEffect } from 'react';

import Checkbox from '../../../../../Checkbox/Checkbox'
import { InputField } from '../../../../../InputFields/InputField'

import './device-issues.css'

export interface IIssue {
  id: number;
  name: string;
}

interface IDeviceIssues {
  searchQuery: string;
  setSearchQuery: any;
  deviceIssues: IIssue[];
  checkedIssues: any[];
  setCheckedIssues: any;
}

const DeviceIssues = ({ searchQuery, setSearchQuery, deviceIssues, checkedIssues, setCheckedIssues }: IDeviceIssues) => {

  useEffect(() => {
    setSearchQuery('');
  }, [])

  const addOrToggleCheckedIssues = (issueId: string) => {
    let newArr: string[] = [];
    if (checkedIssues.includes(issueId)) {
      newArr = checkedIssues.filter(item => item !== issueId);
    } else {
      newArr = [...checkedIssues, issueId];
    }
    setCheckedIssues(newArr);
  }

  const clearAllChecked = () => {
    setCheckedIssues([])
  }

  const filterIssues = (issues: IIssue[]) => {
    return issues.filter(issue => issue.name.toLowerCase().includes(searchQuery.toLowerCase()))
  }

  return (
    <div className='physical-damage-claim__container'>
      <p className="physical-damage-claim__title">Please select all the issues the device currently has form the list below.</p>

      <InputField
        placeholder='Search Issues'
        value={searchQuery}
        onChange={(e: any) => setSearchQuery(e.target.value)}
        inputStyles={{ background: '#FFF', marginBottom: '16px' }}
      />
      <div className="physical-damage-claim__issues-list-wrapper">

        <div className='physical-damage-claim__issues-list'>
          {filterIssues(deviceIssues).map(issue => (
            <div className='physical-damage-claim__issue'>
              <Checkbox checked={checkedIssues.includes(issue.id.toString())} onClick={() => addOrToggleCheckedIssues(issue.id.toString())} />
              <p>{issue.name}</p>
            </div>
          ))}

        </div>
        <div className="list-shadow-bottom" style={{ bottom: -1 }} />
      </div>
      <div className='physical-damage-claim__issue-footer'>
        <p>{`${checkedIssues.length} Selected`}</p>
        <p onClick={clearAllChecked}>Clear All</p>
      </div>
    </div>
  )
}

export default DeviceIssues