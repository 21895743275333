import { useEffect } from 'react'

import { InputField } from '../../../../../InputFields/InputField'
import Switch from '../../../../../Switch/Switch'

import './device-condition.css'

export interface ICondition {
  id: number;
  name: string;
}

interface IDeviceCondition {
  searchQuery: string;
  setSearchQuery: any;
  deviceConditions: ICondition[];
  selectedConditions: string[];
  setSelectedConditions: any;
}

const DeviceCondition = ({ searchQuery, setSearchQuery, deviceConditions, selectedConditions, setSelectedConditions }: IDeviceCondition) => {

  useEffect(() => {
    setSearchQuery('');
  }, [])

  const addOrToggleSelectedConditions = (conditionId: string) => {
    let newArr: string[] = [];
    if (selectedConditions.includes(conditionId)) {
      newArr = selectedConditions.filter(item => item !== conditionId);
    } else {
      newArr = [...selectedConditions, conditionId];
    }
    setSelectedConditions(newArr);
  }

  const filterConditions = (conditions: ICondition[]) => {
    return conditions.filter(condition => condition.name.toLowerCase().includes(searchQuery.toLowerCase()))
  }

  return (
    <div className='physical-damage-claim__container'>
      <p className="physical-damage-claim__title">Please toggle on the respective conditions as recognised on the device.</p>

      <InputField
        placeholder='Search Condition'
        value={searchQuery}
        onChange={(e: any) => setSearchQuery(e.target.value)}
        inputStyles={{ background: '#FFF', marginBottom: '16px' }}
      />
      <div className='physical-damage-claim__issues-list-wrapper'>
        <div className='physical-damage-claim__issues-list'>
          {filterConditions(deviceConditions).map(condition => (
            <div className='physical-damage-claim__condition'>
              <p>{condition.name}</p>
              <Switch isOn={selectedConditions.includes(condition.id.toString())} setIsOn={() => addOrToggleSelectedConditions(condition.id.toString())} />
            </div>
          ))}

        </div>
        <div className="list-shadow-bottom" style={{ bottom: -1 }} />
      </div>
    </div>
  )
}

export default DeviceCondition