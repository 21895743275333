import React from 'react'

interface ILeftArrow {
  strokeColor?: string
}

const LeftArrow = ({ strokeColor = '#1F2937' }: ILeftArrow) => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 5.5L0.5 5.5" stroke={strokeColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.5 10.5L0.5 5.5L5.5 0.5" stroke={strokeColor} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default LeftArrow