import React from 'react';

interface CustomersIcon {
    fillColor: string;
}

export function CustomersIcon({ fillColor }: CustomersIcon): JSX.Element {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z" fill={ fillColor } />
                <path d="M7.99998 9.33333C3.49998 9.33333 1.33331 12.1413 1.33331 13.7773V14.6667C1.33331 15.0203 1.47379 15.3594 1.72384 15.6095C1.97389 15.8595 2.31302 16 2.66665 16H13.3333C13.6869 16 14.0261 15.8595 14.2761 15.6095C14.5262 15.3594 14.6666 15.0203 14.6666 14.6667V13.7773C14.6666 12.1413 12.5 9.33333 7.99998 9.33333Z" fill={ fillColor } />
            </svg>
        </div>
    )
}