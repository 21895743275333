import './loading-spinner.css'

interface ILoadingSpinner {
  fillColor?: string;
  message?: string;
  /**
   * Determines whether the spinner is rendered with it surrounding container. Defaults to true.
   */
  withContainer?: boolean;
  size?: number;
}

const LoadingSpinner = ({ fillColor = '#65A30D', message, withContainer = true, size = 80 }: ILoadingSpinner) => {
  const getSpinner = () => {
    return <svg className='loading-spinner' width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM40 72C57.6731 72 72 57.6731 72 40C72 22.3269 57.6731 8 40 8C22.3269 8 8 22.3269 8 40C8 57.6731 22.3269 72 40 72Z" fill={fillColor} fill-opacity="0.08" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7772 6.74126C23.2428 3.08925 29.5063 0.852061 36.0065 0.199862C38.2046 -0.020682 40 1.7909 40 4.00004C40 6.20918 38.2002 7.97382 36.008 8.24663C20.2189 10.2115 8.00002 23.6788 8.00002 40C8.00002 47.3986 10.5109 54.2107 14.7269 59.6308C16.0833 61.3745 16.1063 63.8938 14.5442 65.4559C12.9821 67.018 10.4316 67.0294 9.03323 65.3192C4.89801 60.2616 2.05102 54.2508 0.768607 47.8037C-0.774802 40.0444 0.0173314 32.0018 3.04484 24.6927C6.07234 17.3837 11.1992 11.1365 17.7772 6.74126Z" fill="url(#paint0_linear_57_7860)" />
      <defs>
        <linearGradient id="paint0_linear_57_7860" x1="20" y1="0.181641" x2="20" y2="58.8781" gradientUnits="userSpaceOnUse">
          <stop stop-color={fillColor} />
          <stop offset="1" stop-color={fillColor} stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  }

  return withContainer
    ? <div className='loading-spinner-container'>
      {getSpinner()}

      {message && <p>{message}</p>}
    </div>
    : getSpinner()
}

export default LoadingSpinner