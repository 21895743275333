import SupportingDocuments from '../SupportingDocuments/SupportingDocuments'

import './ClaimLiquidContent.css'
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

interface IClaimLiquidContent {
  data: any;
}

const ClaimLiquidContent = ({ data: { claim } }: IClaimLiquidContent) => {
  
  return (
    <div className='customer-and-device-container'>
      <div className='policy-device-info'>
        <div className="customer-and-device-heading">
          <p>Statement</p>
        </div>

        <div className='policy-details'>
          <div>
            <p className='info' data-testid="statement">{claim.statement}</p>
          </div>
        </div>
      </div>

      <div className='liquid-claim__docs-wrapper'>
        <SupportingDocuments data={claim.documents} />
      </div>
    </div>
  )
}

export default ClaimLiquidContent