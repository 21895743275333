import React from 'react';
import './file-display.css'
import { ReactComponent as ClaimFile } from "../../../assets/svg/claims/claim-file.svg"
import { ReactComponent as CloseIconFilled } from "../../../assets/svg/claims/close-icon-filled.svg"

interface IFileDisplay {
    data: File
    onClick?: any;
}

const FileDisplay = ({ data, onClick }: IFileDisplay) => {
    return (
        <div className='file-container'>
            <div className='file-items'>
                <ClaimFile />
                <div className='file-text-group'>
                    <p className='file-text'>{data.name}</p>
                    <p className='file-text-size'>{Math.round(data.size / 1000)} KB</p>
                </div>
            </div>
            <CloseIconFilled onClick={onClick}/>
        </div>
    );

};

export default FileDisplay