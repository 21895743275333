import { useState } from 'react';
import { getConditionText, getIssueText } from '../../../utils/claimUtil';
import { capitalizeEachFirstLetter } from '../../../utils/stringUtils';

import './IssuesAndConditions.css'

interface IIssueAndConditions {
    heading: string;
    data: any;
    forIssue: boolean;
}

const IssueAndConditions = ({ heading, data, forIssue }: IIssueAndConditions) => {

    const [dataList, setDataList] = useState<any[]>([])

    const renderInfo = () => {
        const keys = Object.keys(data)
        let list: any[] = []

        keys.forEach(key => {
            if (typeof data[key] === 'boolean' && data[key]) {
                let apiKeyName = forIssue ? getIssueText(key) : getConditionText(key)
                list = [
                    ...list,
                    <tr className='device-conditions-table-row' key={apiKeyName}>
                        <td className="device-condtitions-table-data">
                            <p className="supporting-doc__title">
                                {capitalizeEachFirstLetter(apiKeyName)}
                            </p>
                        </td>
                    </tr>
                ]
            }
        })
        // console.log('list', list)
        return list
    }

    return (
        <div className='issues-and-conditions__wrapper'>
            <div className="device-condtitions-heading">
                <p>{heading}</p>
            </div>
            <div className='issues-and-conditions__details'>
                <table className="device-conditions-table">
                    <thead>
                        {renderInfo()}
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default IssueAndConditions