export const stringToFormattedDate = (date: string) => {
  const newDate = new Date(date)
    .toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
  return newDate;
}

/**
 * Take a date and returns it as a yyyy-mm-dd hh:ii:ss string
 * @param date Date object to be converted to string
 * @returns Formatted date string
 */
export const dateToFormattedString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const addYearsToDate = (date: Date, years: number) => {
  var newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);

  return newDate;
}

export const timeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;

  if (diff < minute) {
    return 'just now';
  } else if (diff < hour) {
    const minutesAgo = Math.floor(diff / minute);
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else if (diff < day) {
    const hoursAgo = Math.floor(diff / hour);
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else if (diff < week) {
    const daysAgo = Math.floor(diff / day);
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  } else {
    const weeksAgo = Math.floor(diff / week);
    return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
  }
}
