import React, { useState, useRef } from 'react';

// components
import FileDisplay from './FileDisplay/fileDisplay';
import UploadFileModal from '../UploadFileModal/UploadFileModal';
import Button from '../Button/Button';
import { ReactComponent as DefaultFile } from '../../assets/svg/claims/default-file.svg';

import './DragDropFile.css'

interface IDragDropFile {
  uploadedFiles: File[];
  setUploadedFiles: any;
}

const DragDropFile = ({ uploadedFiles, setUploadedFiles }: IDragDropFile) => {
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
  const [rejectedFiles, setRejectedFiles] = React.useState({
    name: "",
    count: 0,
  });

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFiles = function (newFiles: any, newFiles2: any) {
    let foundFileOverLimit = false;
    let rejectedFiles: string[] = [];

    newFiles.forEach(async (file: File) => {
      if (file.size > 20971520) {
        rejectedFiles.push(file.name)
        foundFileOverLimit = true;
        newFiles2 = newFiles2.filter((f: any) => f !== file)
      }
    })

    setRejectedFiles({ name: rejectedFiles[0], count: rejectedFiles.length - 1 });
    if (newFiles2.length > 0) {
      setUploadedFiles([...uploadedFiles, ...newFiles2])
    }

    if (foundFileOverLimit) {
      setOpenUploadFileModal(true)
    }
  }

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const newFiles: File[] = [...e.dataTransfer.files];
      let newFiles2 = [...e.dataTransfer.files];

      handleFiles(newFiles, newFiles2)
    };
  }

  const handleChange = function (e: any) {
    e.preventDefault();
    const newFiles = [...e.target.files];
    let newFiles2 = [...e.target.files];

    handleFiles(newFiles, newFiles2)
  };

  const onButtonClick = (e: any) => {
    inputRef.current!.click();
  };

  const removeFile = (name: string, lastModified: number) => {
    let newFileList = uploadedFiles.filter(file => file.name !== name && file.lastModified !== lastModified)
    setUploadedFiles(newFileList)
  }

  return (
    <>
      <div className='file-upload-header'>
        <p className='file-upload-header__p'>Supporting Documents</p>
        <input ref={inputRef} type="file" id="input-file-upload" data-testid="file-input" multiple={true} onChange={handleChange} accept=".pdf,.doc,.jpeg,.png" />
        <Button classes="upload-button" text={"Upload"} onClick={onButtonClick}></Button>
      </div>

      {uploadedFiles.length > 0 ?

        uploadedFiles.map((file: File, index: number) => (

          <FileDisplay key={index} data={file} onClick={() => removeFile(file.name, file.lastModified)} />

        ))

        :

        <form id="form-file-upload" encType="multipart/form-data" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
            <div>
              <div>
                <DefaultFile />
                <p>Drag and drop your files here</p>
                <p className='supporting-doc-sub-text'>or click to upload files</p>
              </div>
            </div>
          </label>
          {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </form>
      }

      {openUploadFileModal && <UploadFileModal onButtonClick={onButtonClick} setOpenModal={setOpenUploadFileModal} rejectedFiles={rejectedFiles} />}
    </>
  );

};

export default DragDropFile