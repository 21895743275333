import React, { useState } from "react";
import "./Header.css"

import Searchbar from "../Search/Header/Searchbar";
import Notification from "../Notification/Notification";
import HeaderProfile from "../Profile/HeaderProfile/HeaderProfile";

export default function Header() {
    const [searchQuery, setSearchQuery] = useState<string>('');
  
    return (
        <div className="header-container">
            <div className="header-searchbar">
                <Searchbar/>
            </div>
            <div className="header-left-group">
                <Notification />
                <HeaderProfile/>
            </div>
        </div>
    )
}