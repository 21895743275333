import ReactPaginate from 'react-paginate'

import { EllipsesIcon } from '../../assets/svg/pagination/EllipsesIcon'
import { NextIcon } from '../../assets/svg/pagination/NextIcon'
import { PreviousIcon } from '../../assets/svg/pagination/PreviousIcon'

import './Footer.css'

interface IFooter {
  data: any,
  itemsPerPage?: number,
  onPageChange: (pageIndex: number) => void
  // onPageChange: any
}

const Footer = ({ data, itemsPerPage, onPageChange }: IFooter) => {
  const { currentPage, lastPage, perPage, total } = data;

  return (
    <div className='footer-container'>
      <p>Showing <strong>{(currentPage * perPage) - (perPage - 1)}</strong> to <strong>{currentPage === lastPage ? total : (currentPage * perPage)}</strong> of <strong>{total}</strong> entries</p>

      <ReactPaginate
        className='pag'
        activeClassName='pag-active'
        pageCount={lastPage}
        forcePage={currentPage - 1}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        onPageChange={(item) => onPageChange(item.selected + 1)}
        previousLabel={<PreviousIcon fillColor={(currentPage - 1) === 0 ? '#D1D5DB' : undefined} />}
        nextLabel={<NextIcon fillColor={(currentPage - 1) === lastPage - 1 ? '#D1D5DB' : undefined} />}
        breakLabel={<EllipsesIcon />}
      />
    </div>
  )
}

export default Footer