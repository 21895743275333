export function TheftIcon(): JSX.Element {
    return (
        <div>
            <svg width="60" height="64" viewBox="0 0 87 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3638 60.1428C41.8494 59.4216 42.664 58.9897 43.536 58.9897C44.4081 58.9897 45.2279 59.4216 45.7135 60.1428C49.8545 66.2645 58.852 79.5686 63.4107 86.3151C63.959 87.1201 64.0112 88.16 63.5569 89.0195C63.1026 89.8791 62.2096 90.4158 61.2383 90.4158C52.5229 90.4158 34.5544 90.4158 25.839 90.4158C24.8678 90.4158 23.9747 89.8791 23.5204 89.0195C23.0661 88.16 23.1183 87.1201 23.6666 86.3151C28.2254 79.5686 37.2228 66.2645 41.3638 60.1428Z" stroke="#65A30D" stroke-width="3.3784" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0317 18.7646L42.8606 25.7248C42.8606 25.7248 31.2392 30.7068 35.0317 18.7646Z" stroke="#65A30D" stroke-width="1.82106" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1167 18.7642L44.2878 25.7243C44.2878 25.7243 55.9092 30.7063 52.1167 18.7642Z" stroke="#65A30D" stroke-width="1.82106" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M27.7085 28.6973C27.7085 28.6973 40.1997 39.3099 59.4953 28.8431" stroke="#65A30D" stroke-width="1.82255" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M43.5494 42.7744C38.6126 42.7744 34.5733 40.8716 31.4317 37.066C28.5122 33.5295 26.9481 29.0524 26.7417 23.6376C26.726 23.2244 26.7192 22.8085 26.7192 22.3872C26.7192 16.4069 28.2901 11.514 31.4317 7.70841C34.5733 3.9028 38.6126 2 43.5494 2C48.4863 2 52.5255 3.9028 55.6671 7.70841C58.8088 11.514 60.3796 16.4069 60.3796 22.3872C60.3796 28.3674 58.8088 33.2604 55.6671 37.066C52.5255 40.8716 48.4863 42.7744 43.5494 42.7744Z" stroke="#65A30D" stroke-width="3.38363" stroke-miterlimit="2" stroke-linejoin="round" />
                <path d="M28.4282 13.9556H58.6955" stroke="#65A30D" stroke-width="1.82046" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M35.2676 52.293C25.2154 54.3805 20.9457 56.4628 17.0269 58.5503C13.6048 60.3619 10.9755 63.3866 9.65405 67.0306C7.37567 73.288 3.84872 82.9969 2.12627 87.7342C1.89387 88.3745 1.98503 89.0878 2.37691 89.6396C2.76424 90.1966 3.40218 90.5297 4.08114 90.5297H83.0544C83.7333 90.5297 84.3713 90.1966 84.7632 89.6396C85.1505 89.0878 85.2462 88.3745 85.0138 87.7342C83.2913 82.9969 79.7598 73.288 77.486 67.0306C76.16 63.3866 73.5308 60.3619 70.1086 58.5503C66.1898 56.4628 61.9201 54.3805 51.8725 52.293" stroke="#65A30D" stroke-width="3.40448" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M36.2476 42.1807V50.1732C36.2476 52.1235 37.0268 53.9918 38.4029 55.3725C39.7836 56.7486 41.6519 57.5233 43.6022 57.5233C45.5525 57.5233 47.4207 56.7486 48.8014 55.3725C50.1776 53.9918 50.9522 52.1235 50.9522 50.1732V42.1807" stroke="#65A30D" stroke-width="3.41757" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.7983 63.2598H39.0159" stroke="#65A30D" stroke-width="3.41757" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M48.2031 63.2598H75.3432" stroke="#65A30D" stroke-width="3.41757" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.66309 73.7764H31.7005" stroke="#65A30D" stroke-width="3.41921" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M55.2148 73.7769H79.4223" stroke="#65A30D" stroke-width="3.41921" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.06787 83.3047H25.5995" stroke="#65A30D" stroke-width="3.41853" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M62.1362 83.3047H83.1236" stroke="#65A30D" stroke-width="3.41853" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M43.5293 64.9229V79.7012" stroke="#65A30D" stroke-width="3.1715" stroke-miterlimit="1.5" stroke-linejoin="round" />
                <path d="M43.5293 83.6641V87.0826" stroke="#65A30D" stroke-width="3.11881" stroke-miterlimit="1.5" stroke-linejoin="round" />
            </svg>

        </div>
    )
}