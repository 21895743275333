import axios from "axios";
import { network } from "../../services";

const getAllUsers = async (page: number, limit: number, search: string, sortBy: string, bearerToken: string) => {
  const response = await axios.get(`${network.baseApi}/user?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}`, { headers: { Authorization: bearerToken } })
  return response
}

const createUser = async (user: any, bearerToken: string) => {
  const response = await axios.post(`${network.baseApi}/user`, user, { headers: { Authorization: bearerToken } })
  return response
}

const UserService = {
  getAllUsers,
  createUser
}

export default UserService
