import './statement.css';
import { ChangeEvent } from 'react';

interface Statement {
    claimType: string;
    statement?: string;
    setStatement?: any;
}

const TheftStatement = ({ claimType, statement, setStatement }: Statement) => {
    const getStatementTitle = (claimType: string) => {
        switch (claimType.toLowerCase()) {
            case 'theft':
                return 'Please take a statement from the owner on how the device got stolen.'
            case 'liquid damage':
                return 'Please take a statement from the owner on how the device got damaged from some liquid.'
        }
    }

    return (
        <div className='statement__container'>
            <p className="theft-claim__title">{getStatementTitle(claimType)}</p>
            <div className="theft-claim__section-fw">
                <p className="theft-claim__label"> Statement</p>
                <textarea
                    className="statement__text"
                    placeholder="Type..."
                    value={statement}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => { setStatement(e.target.value) }}
                />
            </div>
        </div>
    );
}

export default TheftStatement;