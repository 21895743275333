import { stringToFormattedDate } from '../../../utils/dateUtil';
import SupportingDocuments from '../SupportingDocuments/SupportingDocuments';
import { useAuthState } from '../../../contexts';
import './ClaimTheftContent.css'
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
/* ---------- Most style are coming from CustomerAndDeviceInfo.css ---------- */

interface IClaimTheftContent {
  data: any;
}

const ClaimTheftContent = ({ data: { claim } }: IClaimTheftContent) => {
  
  return (
    <>
      <div className='customer-and-device-container'>
        <div className='policy-customer-info'>
          <div className="customer-and-device-heading">
            <p>Theft Details</p>
          </div>

          <div className='policy-details'>

            <div>
              <p className='label'>Date Lost</p>
              <p className='info' data-testid="dateLost">{stringToFormattedDate(claim.theftDetails.dateLost)}</p>
            </div>
            <div>
              <p className='label'>Station Reported</p>
              <p className='info' data-testid="stationReported">{claim.theftDetails.policeStation}</p>
            </div>
            <div>
              <p className='label'>Recording Officer</p>
              <p className='info' data-testid="recordingOfficer">{claim.theftDetails.recordingOfficer}</p>
            </div>
            <div>
              <p className='label'>Date Reported</p>
              <p className='info' data-testid="dateReported">{stringToFormattedDate(claim.theftDetails.dateReported)}</p>
            </div>
            <div>
              <p className='label'>Sim Disabled</p>
              <p className='info' data-testid="simDisabled">{claim.theftDetails.numberShutdown ? 'Yes' : 'No'}</p>
            </div>
          </div>
        </div>

        <div className='policy-device-info'>
          <div className="customer-and-device-heading">
            <p>Statement</p>
          </div>

          <div className='policy-details'>
            <div>
              <p className='info' data-testid="statement">{claim.statement}</p>
            </div>
          </div>
        </div>
      </div>

      <SupportingDocuments data={claim.documents} />
    </>
  )
}

export default ClaimTheftContent