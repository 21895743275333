import { Navigate, Outlet } from 'react-router-dom';

import { useAuthState } from '../contexts';

const PrivateRoutes = () => {
    const { user } = useAuthState();

    return (
        user ? <Outlet /> : <Navigate to='/login' />
    )
}
export default PrivateRoutes;