import React from 'react'

interface ICheckedIcon {
  fillColor?: string
}

export const CheckedIcon = ({ fillColor = '#1F2937' }: ICheckedIcon) => {
  return (
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.83973 0.876738C9.58975 0.885178 9.35291 0.990608 9.17955 1.17092C7.34006 3.01429 5.93543 4.55397 4.22801 6.29633L2.40958 4.75988C2.21466 4.5951 1.96227 4.51472 1.70801 4.53629C1.45361 4.55799 1.21838 4.6799 1.05414 4.87522C0.889894 5.07054 0.810185 5.32318 0.832559 5.57748C0.854797 5.83175 0.977511 6.0666 1.17323 6.23029L3.66995 8.34294C3.85362 8.49767 4.08859 8.57792 4.32866 8.56787C4.56859 8.55795 4.79607 8.45842 4.96634 8.28895C7.03475 6.21612 8.50732 4.56039 10.5359 2.52737C10.7228 2.34665 10.8285 2.09799 10.8289 1.83811C10.8291 1.57821 10.7242 1.32917 10.5377 1.14805C10.3514 0.966934 10.0996 0.86897 9.83973 0.876738Z" fill={fillColor} />
    </svg>
  )
}
