import { useContext, useEffect, useState } from 'react'

import UserContext from '../../../contexts/User/UserContext'
import Button from '../../Button/Button'
import ContentControls from '../../ContentControls/ContentControls'
import { IDropdown } from '../../Dropdown/Dropdown'
import ContentHeader from '../../ContentHeader/ContentHeader'
import { Table } from './Table/Table'
import Footer from '../../Footer/Footer'
import CreateUserModal from './CreateUserModal/CreateUserModal'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import PlaceholderContent from '../../PlaceholderContent/PlaceholderContent'
import SearchContext from '../../../contexts/Search/SearchContext'

const Users = () => {
  const { data, isLoading, sortBy, setSortBy, filterBy, setFilterBy, itemsPerPage, setItemsPerPage, createUser, getAllUsers } = useContext(UserContext);
  const { setSearchQuery } = useContext(SearchContext)

  const [isItemsPerPageVisible, setIsItemsPerPageVisible] = useState<boolean>(false);
  const [isSortVisible, setIsSortVisible] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  useEffect(() => {
    getAllUsers(1)

    return () => {
      // reset searchQuery
      setSearchQuery('any', '')
    }
  }, [itemsPerPage, sortBy])

  /* ---------- Policies Dropdown Props ---------- */
  const policyItemsPerPageProps: IDropdown = {
    text: `${itemsPerPage} items per page`,
    visible: isItemsPerPageVisible,
    toggleVisibility: () => setIsItemsPerPageVisible(!isItemsPerPageVisible),
    options: [
      {
        id: 5,
        text: '5 items per page',
        selected: itemsPerPage === 5,
        onClick: () => setItemsPerPage(5)
      },
      {
        id: 10,
        text: '10 items per page',
        selected: itemsPerPage === 10,
        onClick: () => setItemsPerPage(10)
      }
    ]
  }

  const policySortProps: IDropdown = {
    text: 'Sort By Details',
    visible: isSortVisible,
    toggleVisibility: () => setIsSortVisible(!isSortVisible),
    options: [
      {
        id: 1,
        text: 'Name',
        selected: sortBy === 'name',
        onClick: () => setSortBy('name'),
      },
    ]
  }

  const policyFilterProps: IDropdown = {
    text: 'Filter',
    visible: isFilterVisible,
    toggleVisibility: () => setIsFilterVisible(!isFilterVisible)
  }
  /* ---------- End of Policies Dropdown Props ---------- */

  const contentInfoArray = [
    <p><strong>100</strong> Active</p>,
    <p><strong>40</strong> Suspended</p>
  ]

  return <>
    {/* {
        (isLoading) &&
        <LoadingSpinner message='Saving the new user' />
      } */}

    {
      (data.userData !== undefined && data.userData.length > 0) &&
      <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
        <ContentHeader
          title='Users'
          actions={[
            <Button key={1} classes='p-btn sms-btn' text='Create User' onClick={() => setShowCreateUserModal(true)} />
          ]}
        />

        <ContentControls
          contentInfoLabel={<p><strong>{data.meta.total}</strong> Total Users</p>}
          contentInfoArray={contentInfoArray}
          itemsPerPageProps={policyItemsPerPageProps}
          sortProps={policySortProps}
          filterProps={policyFilterProps}
        />
      </div>
    }

    {
      (isLoading) &&
      <LoadingSpinner message='Just a moment. Retrieving the users.' />
    }

    {
      (!isLoading && (data.userData === undefined || data.userData.length === 0)) &&
      <PlaceholderContent text='Sorry. The users could not be found.' />
    }

    {
      (!isLoading && data.userData !== undefined && data.userData.length > 0) &&
      <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingBottom: '48px' }}>

        <Table headers={['Name', 'Email', 'Role', 'Position', 'Status']} data={data || []} />
      </div>
    }

    {
      (data.userData !== undefined && data.userData.length > 0) &&
      <div style={{ paddingLeft: '48px', paddingRight: '48px', paddingTop: '48px' }}>
        <Footer data={data.meta || {}} itemsPerPage={itemsPerPage} onPageChange={getAllUsers} />
      </div>
    }

    {
      showCreateUserModal &&
      <CreateUserModal setShowCreateUserModal={setShowCreateUserModal} createUser={createUser} />
    }
  </>
}

export default Users