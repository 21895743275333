import React, { CSSProperties, ReactNode } from 'react'

import './Button.css';

interface IButton {
  text: string | ReactNode;
  classes?: string;
  styles?: CSSProperties;
  onClick: any;
}

const Button = ({ text, classes, styles = {}, onClick }: IButton) => {
  return (
    <button className={classes} style={{ ...styles }} onClick={onClick}>
      {text}
    </button>
  )
}

export default Button