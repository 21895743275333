import { useNavigate } from 'react-router';

import { Status } from '../../../components/Status/Status'
import { stringToFormattedDate } from '../../../utils/dateUtil';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import { ReactComponent as NoClaims } from '../../../assets/svg/no-claims.svg'
import Footer from '../../../components/Footer/Footer';

import './PolicyClaimsTable.css'
import { useContext, useEffect } from 'react';
import PolicyContext from '../../../contexts/Policy/PolicyContext';

interface IPolicyClaimsTable {
  subHeaders: string[];
  data: any;
}

const PolicyClaimsTable = ({ subHeaders, data: { claims, policy } }: IPolicyClaimsTable) => {
  const { getClaimsForPolicy } = useContext(PolicyContext);

  const navigate = useNavigate()

  // useEffect(() => {
  //   getClaimsForPolicy(policy.imei, 1);
  // }, []);

  return (
    <>
      <div className='policy-claims-table-heading'>
        <p>Claims</p>
      </div>

      {claims.claimsData === undefined || claims.claimsData.length === 0 || claims.message
        ? <div className='policy-claims__no-claims'>
          <NoClaims />
          <p className='policy-claims__no-claims-text'>No Claims Available</p>
        </div>
        : <table className="policy-table">
          <thead>
            <tr>
              {subHeaders?.map((header: any) => {
                return (
                  <td key={header}>
                    <p className="sub-heading">{header}</p>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {claims.claimsData.map((datum: any, index: number) => {
              return (
                <tr key={datum.id} className='policy-table-row' onClick={
                  () => navigate(`/policies/${policy.policyNumber.toUpperCase()}/claim`, { state: { imei: policy.imei, claimId: datum.id } })
                }>
                  <td className="policy-table-data">
                    <p className="policy-claim-data-p">{capitalizeFirstLetter(datum.type)}</p>
                  </td>
                  <td>
                    <p className="policy-claim-data-p">{stringToFormattedDate(datum.dateCreated)}</p>
                  </td>
                  <td>
                    <p className="policy-claim-data-p">{stringToFormattedDate(datum.lastUpdated)}</p>
                  </td>
                  <td>
                    <div className="status">
                      <Status title={datum.status}
                        classes={datum.status !== "inactive" ? 'active' : 'inactive'} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>}
      <div style={{ paddingBottom: '48px', paddingTop: '48px' }}>
        <Footer data={claims.meta || {}} onPageChange={(pageNumber) => getClaimsForPolicy(policy.imei, pageNumber)} />
      </div>
    </>
  )
}

export default PolicyClaimsTable