import { createContext, useContext, useEffect, useReducer, useState } from "react";

import PolicyService from "../../hooks/api/PolicyService";
import { createAction } from "../../utils/reducerUtils";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { useAuthState } from "../../contexts";
import { POLICY_ACTION_TYPES } from "./policyTypes";
import { handleRateLimit } from "../../utils/rateLimitUtil";
import SearchContext from "../Search/SearchContext";

const POLICY_INITIAL_STATE = {
  data: {
    meta: {},
    policyData: [],
  },
  dataForClaims: {
    meta: {},
    claimsData: [],
  },
  isLoading: false,
  sortBy: undefined,
  setSortBy: (sortBy: string) => { },
  filterBy: undefined,
  setFilterBy: (filterBy: string) => { },
  itemsPerPage: undefined,
  setItemsPerPage: (id: number) => { },
  getAllPolicies: (page: number) => { },
  getClaimsForPolicy: (imei: string, page: number) => { },
  findPolicy: (policyNum: string) => { }
}

const policyLocalStorageNames = {
  'policySort': 'policySort',
  'policyFilter': 'policyFilter',
  'policyItemsPerPage': 'policyItemsPerPage'
}

interface IPolicyData {
  data: {
    meta: any;
    policyData: any[],
  };
  dataForClaims: {
    meta: any,
    claimsData: any[],
  },
  isLoading: boolean;
  sortBy: string | undefined;
  setSortBy: (sortBy: string) => any,
  filterBy: string | undefined,
  setFilterBy: (filterBy: string) => void,
  itemsPerPage: number | undefined,
  setItemsPerPage: (id: number) => void,
  /**
   * Fetches all policies from the database for the given `page`.
   */
  getAllPolicies: (page: number) => void,
  /**
   * Returns the list of claims for a policy using the device `imei`.
   */
  getClaimsForPolicy: (imei: string, page: number) => void,
  findPolicy: (policyNum: string) => any
}

const PolicyContext = createContext<IPolicyData>(POLICY_INITIAL_STATE);

const policyReducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case POLICY_ACTION_TYPES.SET_POLICY_DATA:
      return {
        ...state,
        data: payload
      }
    case POLICY_ACTION_TYPES.SET_POLICY_SORT_BY:
      return {
        ...state,
        sortBy: payload
      }
    case POLICY_ACTION_TYPES.SET_POLICY_FILTER_BY:
      return {
        ...state,
        filterBy: payload
      }
    case POLICY_ACTION_TYPES.SET_POLICY_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: payload
      }

    case POLICY_ACTION_TYPES.SET_POLICY_CLAIMS_DATA:
      return {
        ...state,
        dataForClaims: payload
      }

    default:
      throw new Error(`Unhandled type ${type} in policyReducer.`);
  }
}

export const PolicyProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(policyReducer, POLICY_INITIAL_STATE);
  const { searchPolicyQuery } = useContext(SearchContext)
  const [isLoading, setIsLoading] = useState(false)
  const { token } = useAuthState();

  const { dataForClaims, sortBy, filterBy, itemsPerPage, data } = state;

  useEffect(() => {
    const sort = localStorage.getItem(policyLocalStorageNames.policySort) || 'customer';
    const filter = localStorage.getItem(policyLocalStorageNames.policyFilter) || '';
    const ipp = Number.parseInt(localStorage.getItem(policyLocalStorageNames.policyItemsPerPage) || '10');

    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_SORT_BY, sort));
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_FILTER_BY, filter));
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_ITEMS_PER_PAGE, ipp));
  }, []);

  function getAllPolicies(page: number) {
    // const isPageEqualCurrentPage = data.meta !== undefined && data.meta.currentPage !== undefined && data.meta.currentPage === page
    // if (isPageEqualCurrentPage) return

    if (sortBy && itemsPerPage) {
      setIsLoading(true)
      const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

      PolicyService.allPolicies(filterBy, sortBy, page, itemsPerPage, bearerToken, searchPolicyQuery).then(response => {
        const policies = response.data;

        setPolicyData(policies);
        setSortBy(sortBy)
        // setFilterBy(filterBy)
        setIsLoading(false)
      }).catch(err => {
        setPolicyData({ policyData: undefined, meta: {} });
        handleRateLimit(err)
        setIsLoading(false)
      })
    }
  }

  function getClaimsForPolicy(policyImei: string, page: number) {
    setIsLoading(true)
    const bearerToken = `${capitalizeFirstLetter(token.type)} ${token.token}`;

    PolicyService.getClaimsForPolicy(page, policyImei, bearerToken).then(response => {
      const policyClaims = response.data;
      setPolicyClaimsData(policyClaims);

      setIsLoading(false)
    }).catch(err => {
      handleRateLimit(err)
      setIsLoading(false)
    })

  }


  const findPolicy = (policyImei: string) => {
    return data.policyData.find((policy: any) => policy.imei === policyImei);
  }

  const setPolicyData = (data: any) => {
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_DATA, data));
  }

  const setPolicyClaimsData = (dataForClaims: any) => {
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_CLAIMS_DATA, dataForClaims));
  }

  const setSortBy = (sortBy: string) => {
    localStorage.setItem(policyLocalStorageNames.policySort, sortBy);
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_SORT_BY, sortBy));
  }

  const setFilterBy = (filterBy: string) => {
    localStorage.setItem(policyLocalStorageNames.policyFilter, filterBy)
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_FILTER_BY, filterBy));
  }

  const setItemsPerPage = (id: number) => {
    localStorage.setItem(policyLocalStorageNames.policyItemsPerPage, id.toString())
    dispatch(createAction(POLICY_ACTION_TYPES.SET_POLICY_ITEMS_PER_PAGE, id));
  }

  return (
    <PolicyContext.Provider value={{ dataForClaims, data, isLoading, sortBy, setSortBy, filterBy, setFilterBy, itemsPerPage, setItemsPerPage, getAllPolicies, findPolicy, getClaimsForPolicy }}>
      {children}
    </PolicyContext.Provider>
  );
}

export default PolicyContext;