import { useState } from 'react';

import { ReactComponent as ColumnIcon } from "../../../../assets/svg/table/column-sort.svg";
import Checkbox from "../../../Checkbox/Checkbox";


import "../../Policies/Table/Table.css"
import "./Table.css"
import { useLocation, useNavigate } from 'react-router-dom';

interface InputField {
  headers?: any;
  data?: any;
}

export function Table({ headers, data, }: InputField): JSX.Element {

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const navigate = useNavigate()
  const location = useLocation()

  const addOrToggleCheckedItems = (dataId: string) => {
    let newArr: string[] = [];
    if (checkedItems.includes(dataId)) {
      newArr = checkedItems.filter(item => item !== dataId);
    } else {
      newArr = [...checkedItems, dataId];
    }
    setCheckedItems(newArr);
  }

  const formatDate = (dateString:string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

//   const checkAllItemsToggle = () => {
//     if (checkedItems.length === data.policyData.length) {
//       setCheckedItems([]);
//     } else {
//       setCheckedItems(data.policyData.map((dat: any) => dat.policyNumber));
//     }
//   }

  const checkClaimType = (claimType: string): string => {
    switch (claimType.toLowerCase()) {
        case 'physical damage':
          return 'Physical';
        case 'liquid damage':
          return 'Liquid';
        case 'theft':
          return 'Theft';
        default:
          return 'N/A'
    }
  }

  const formatStatus = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'submitted for review':
        return 'Review'
      case 'close':
        return 'Closed'
      case 'accounts confirmation':
        return 'Accounts'
      default:
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
    }
  }

  const viewClaim = (imei: string, claimId: string) => {
    navigate(`${location.pathname}/claim`, { state: { imei, claimId } })
  }

  return (
    <table className="table">
      <thead className="table-head">
        <tr>
          {/* <th className="table-header" scope="col" key={'checkbox'}>
            <Checkbox checked={false} onClick={() => {}} />
          </th> */}
          {headers?.map((header: any) => {
            return (
              <th className="table-header" scope="col" key={header}>
                <p className="table-head-title">
                  {header} 
                  {/* {(typeof header === 'string') && <ColumnIcon />} */}
                </p>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((datum: any, index: number) => {
          return (
            <tr className={`table-row ${(checkedItems.includes(`${index}`)) ? 'checked' : ''}`} key={index} onClick={() => { }}>
              {/* <td>
                <Checkbox checked={checkedItems.includes(`${index}`)} onClick={() => addOrToggleCheckedItems(`${index}`)} />
              </td> */}
              <td className="table-data">
                <p className="table-data-top">{formatDate(datum.created_at)}</p>
                {/* <p className="table-data-bottom">{stringToFormattedDate(datum.dateCreated)}</p> */}
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.id}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.policy.policy_number}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.policy.customer.first_name}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{datum.policy.customer.last_name}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{checkClaimType(datum.type)}</p>
              </td>
              <td>
                <p className="table-data-top__w-400">{formatStatus(datum.status)}</p>
              </td>
              <td>
                  <div className="table-data-top__w-400" style={{paddingRight: '6px'}}>
                    <button 
                      onClick={() => viewClaim(datum.policy.device_imei, datum.id)}
                      className="action-btn">
                        View
                    </button>
                  </div>
              </td>

            </tr>
          );
        })}
      </tbody>
    </table>
  )
}
