import React, { useEffect, useReducer } from "react";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { createBrowserHistory } from "history";

import { parseToken } from "../../utils/tokenUtil";
import { logout } from "./actions";
import { AuthReducer, initialState } from "./reducer";


export const AuthStateContext = React.createContext<any>(undefined);
const AuthDispatchContext = React.createContext({});

export function useAuthState() {
    const context = React.useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider");
    }

    return context;
}

export function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error("useAuthDispatch must be used within a AuthProvider");
    }
    return context;
}

export const AuthProvider = ({ children }: any) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);
    const history = createBrowserHistory();

    useEffect(() => {
        if (user.token) {
            const tokenData = parseToken(user.token.token)
            if (!tokenData) return;
            const dateInSeconds = new Date().getTime()
            const secondsToExpire = ((tokenData.exp * 1000) - dateInSeconds)
            const logoutTimeout = setTimeout(() => {
                dispatch({
                    type: 'LOGOUT_SUCCESS', 
                    payload: null,
                    error: ""
                });
                console.log("token->",user.token.token)
                window.location.href = '/login';
            }, secondsToExpire);

            return () => clearTimeout(logoutTimeout);
        }
    }, [user.token, history])

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
}