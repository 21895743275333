import React from "react";
import "./Notification.css"

import { NotificationIcon } from "../../assets/svg/pageHeader/NotificationIcon";

export default function Notification() {
    return (
        <div className="notification-container">
                <NotificationIcon strokeColor="#6B7280" />
        </div>
    )
}